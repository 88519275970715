import React from 'react'
import styled from 'styled-components'
import { Table, Row, Col } from 'antd'
import moment from 'moment'
import dayjs from 'dayjs'

type Props = {
  monthlyScanReports: App.MonthlyScanReport[]
}

export const Layout = (props: Props) => {
  return (
    <ContentBody>
      <Row justify="end">
      <Col span={4}>{`${ dayjs().hour() < 5 ? dayjs().subtract(1, 'd').set('hour', 5).set('minute', 0).format('YYYY/MM/DD hh:mm') : dayjs().set('hour', 5).set('minute', 0).format('YYYY/MM/DD hh:mm')}`} 時点</Col>
      </Row>
      <Table
        dataSource={props.monthlyScanReports}
        columns={[
          {
            title: '期間',
            dataIndex: 'startAt',
            key: 'startAt',
            sorter: (a, b) => moment(a.startAt.toDate()).unix() - moment(b.startAt.toDate()).unix(),
            defaultSortOrder: 'descend',
            render: (startAt: firebase.firestore.Timestamp) => (
              <span>{dayjs(startAt.toDate()).format('YYYY/MM/DD')} ~ {dayjs(startAt.toDate()).add(1, 'month').subtract(1, 'day').format('YYYY/MM/DD')}</span>
            ),
          },
          {
            title: '有効ユーザー数',
            dataIndex: 'activeUsers',
            key: 'activeUsers',
            sorter: (a, b) => a.activeUsers - b.activeUsers,
          },
          {
            title: 'スキャンユーザー数',
            dataIndex: 'scannedUsers',
            key: 'scannedUsers',
            sorter: (a, b) => a.scannedUsers - b.scannedUsers,
          },
          {
            title: 'スキャン数',
            dataIndex: 'scanReports',
            key: 'scanReports',
            sorter: (a, b) => a.scanReports - b.scanReports,
          },
        ]}
      />
  </ContentBody>
  )
}

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
