import { useMonthlyScanReports } from '@/hooks/useMonthlyScanReports'
import { Layout } from './Layout'

export const useProps = (): FCProps<typeof Layout> => {
  const { monthlyScanReports } = useMonthlyScanReports()

  return {
    monthlyScanReports,
  }
}
