import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Descriptions, Card } from 'antd'

const pageTitle = 'クーポン詳細'

type Props = {
  coupon?: App.Coupon
  deliveredCouponsCount: number
  usedCouponsCount: number
  onPressEdit: (id: App.Coupon['id']) => void
  onPressDelete: (id: App.Coupon['id']) => void
}

export default (props: Props) => {
  return (
    <Root title={pageTitle}>
      <Layout>
        <StyledPageHeader title={pageTitle} />
        <ContentBody>
          <Descriptions bordered>
            <Descriptions.Item label="クーポンID" span={4}>
              {props.coupon?.id}
            </Descriptions.Item>
            <Descriptions.Item label="画像（一覧）" span={4}>
              <Card
                hoverable
                style={{ width: 240 }}
                cover={<img alt="Coupon" src={props.coupon?.imageUrl} />}></Card>
            </Descriptions.Item>
            <Descriptions.Item label="画像（詳細）" span={4}>
              <Card
                hoverable
                style={{ width: 240 }}
                cover={<img alt="Coupon" src={props.coupon?.detailImageUrl} />}></Card>
            </Descriptions.Item>
            <Descriptions.Item label="開始日時・終了日時" span={4}>
              {props.coupon?.publishedAt?.toDate()?.toLocaleString('ja-JP')} ~
              {props.coupon?.finishedAt?.toDate()?.toLocaleString('ja-JP')}
            </Descriptions.Item>
            <Descriptions.Item label="公開/非公開" span={4}>
              {props.coupon?.published ? '公開' : '非公開'}
            </Descriptions.Item>
            <Descriptions.Item label="登録日時" span={4}>
              {props.coupon?.createdAt.toDate().toLocaleString('ja-JP')}
            </Descriptions.Item>
            <Descriptions.Item label="配布数" span={4}>
              {props.deliveredCouponsCount}
            </Descriptions.Item>
            <Descriptions.Item label="利用数数" span={4}>
              {props.usedCouponsCount}
            </Descriptions.Item>
            <Descriptions.Item label="利用率" span={4}>
              {props.deliveredCouponsCount !== 0
                ? Math.round((props.usedCouponsCount / props.deliveredCouponsCount) * 10000) / 100
                : '--'}
              %
            </Descriptions.Item>
          </Descriptions>
        </ContentBody>
      </Layout>
    </Root>
  )
}
const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
