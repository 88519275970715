import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'

const collectionRef = firestore.collection('users')

export const useUser = (params: { userId: App.User['id'] }) => {
  const [user, setUser] = useState<App.User>()
  useEffect(() => {
    params.userId &&
      collectionRef
        .doc(params.userId)
        .onSnapshot(doc => setUser({ id: params.userId, ...doc.data() } as App.User))
  }, [params.userId])

  return user
}
