import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Descriptions, Button, Input, Form, Space, Radio, DatePicker, Result, Divider } from 'antd'
import { Line } from '@ant-design/charts'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { ScanReportsTable } from '@/components/tables/ScanReportsTable'
import { RadioChangeEvent } from 'antd/lib/radio'
import { useConsoleUser } from '@/context/ConsoleUserContext'
import dayjs from 'dayjs'

const pageTitle = 'ユーザー詳細'

type Terms = 'week' | 'month'

type DataType = {
  date: string
  type: string
  value: number
}

type Props = {
  user?: App.User
  emailVerified: boolean
  getContractLabel: (contractStatus: App.User['contractStatus']) => string
  visibleEmailForm: boolean
  onPressEditEmail: () => void
  onChangeEmailAddress: (email: string) => void
  onPressCancelEmail: () => void
  onSubmitEmail: () => void
  isUpdatingEmail: boolean
  visibleContractStatusForm: boolean
  currentContractStatus?: App.User['contractStatus']
  onPressEditContractStatus: () => void
  onChangeContractStatus: (contractStatus?: App.User['contractStatus']) => void
  onPressCancelContractStatus: () => void
  onSubmitContractStatus: (values: any) => void
  isUpdatingContractStatus: boolean
  scanReportsList: App.ScanReport[]
  scanReportsChart: App.ScanReport[]
  terms: Terms
  onChangeTerms: (t: RadioChangeEvent) => void
  data: DataType[]
  oilData: DataType[]
  onPressDownload: () => void
}

export default (props: Props) => {
  const { admin } = useConsoleUser()

  const genderLabel = (gender?: App.User['gender']) => {
    switch (gender) {
      case 'male':
        return '男性'
      case 'female':
        return '女性'
      case 'other':
        return 'その他/無回答'
      default:
        return '未回答'
    }
  }

  const contractStatuses = [
    'underContract',
    'canceled',
    'suspended',
    'cancelScheduled',
    'suspendScheduled',
  ] as App.User['contractStatus'][]

  const config = {
    title: {
      visible: true,
      text: 'スコアの遷移',
    },
    padding: 'auto',
    data: props.data,
    xField: 'date',
    yField: 'value',
    yAxis: {
      label: { visible: true, autoRotate: true, autoHide: true, precision: 0 },
      min: 100,
      max: 0,
    },
    legend: { position: 'top-right' as 'top-right' },
    point: {
      size: 5,
      visible: true,
    },
    seriesField: 'type',
    color: ['#47A6F1', '#12CDDF', '#39B8AE', '#FE7855'],
    responsive: true,
    smooth: true,
  }

  const oilConfig = {
    title: {
      visible: true,
      text: 'スコアの遷移（皮脂）',
    },
    padding: 'auto',
    data: props.oilData,
    xField: 'date',
    yField: 'value',
    yAxis: {
      label: { visible: true, autoRotate: true, autoHide: true, precision: 0 },
      min: 0,
      max: 5,
      tickCount: 5,
    },
    legend: { position: 'top-right' as 'top-right' },
    point: {
      size: 5,
      visible: true,
    },
    seriesField: 'type',
    color: ['#F9A926'],
    responsive: true,
    smooth: true,
  }

  return (
    <Root title={pageTitle}>
      <Layout>
        <StyledPageHeader title={pageTitle} />
        <ContentBody>
          <Descriptions bordered>
            <Descriptions.Item label="ユーザーID" span={4}>
              {props.user?.id}
            </Descriptions.Item>
            <Descriptions.Item label="顧客番号" span={4}>
              {props.user?.customerNumber}
            </Descriptions.Item>
            <Descriptions.Item label="メールアドレス" span={4}>
              {!props.visibleEmailForm ? (
                <>
                  {props.user?.emailAddress}
                  {admin && props.user?.contractStatus !== 'canceled' && (
                    props.emailVerified ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginLeft: 5 }} /> : <CloseCircleTwoTone  twoToneColor="red" style={{ marginLeft: 5 }} />
                  )}
                  {admin && props.user?.contractStatus !== 'canceled' && (
                    <Button danger size="small" onClick={props.onPressEditEmail} style={{ marginLeft: 10 }}>
                      変更
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Form onFinish={props.onSubmitEmail}>
                    <Form.Item
                      name={['user', 'emailAddress']}
                      label=""
                      rules={[
                        {
                          type: 'email',
                          message: '正しい形式のメールアドレスを入力してください。',
                        },
                        {
                          required: true,
                          message: 'メールアドレスを入力してください。',
                        },
                      ]}>
                      <Input
                        defaultValue={props.user?.emailAddress}
                        onChange={e =>
                          e.target?.value ? props.onChangeEmailAddress(e.target.value) : undefined
                        }
                      />
                    </Form.Item>
                    <Button
                      size="small"
                      onClick={props.onPressCancelEmail}
                      style={{ marginLeft: 10 }}
                      disabled={props.isUpdatingEmail}>
                      キャンセル
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      style={{ marginLeft: 10 }}
                      htmlType="submit"
                      loading={props.isUpdatingEmail}>
                      変更する
                    </Button>
                  </Form>
                </>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="ニックネーム" span={4}>
              {props.user?.nickName}
            </Descriptions.Item>
            <Descriptions.Item label="生年月日" span={4}>
              {props.user?.birthday ? props.user?.birthday.toDate().toLocaleDateString('ja-JP') : '未回答'}
            </Descriptions.Item>
            <Descriptions.Item label="性別" span={4}>
              {genderLabel(props.user?.gender)}
            </Descriptions.Item>
            <Descriptions.Item label="郵便番号" span={4}>
              {props.user?.postalCode}
            </Descriptions.Item>
            <Descriptions.Item label="契約状況" span={4}>
            {!props.visibleContractStatusForm ? (
              <>
                {props.user ? `${props.getContractLabel(props.user.contractStatus)}` : ''}
                {props.user && props.user.contractStatusUpdatedAt ?
                  ` (${dayjs(props.user.contractStatusUpdatedAt?.toDate()).format('YYYY/MM/DD')} ${ dayjs(props.user.contractStatusUpdatedAt?.toDate()).isBefore(dayjs()) ? '変更済' : '変更予定' })`
                  : ''}
                <Space />
                {admin && props.user?.contractStatus !== 'canceled' && (
                  <Button danger size="small" onClick={props.onPressEditContractStatus} style={{ marginLeft: 10 }}>
                    変更
                  </Button>
                )}
              </>
            ) : (
              <>
                  <Form onFinish={props.onSubmitContractStatus}>
                    <Form.Item
                      name="contractStatus"
                      label="">
                      <Radio.Group
                        defaultValue={props.user?.contractStatus}
                        onChange={e =>
                          e.target?.value ? props.onChangeContractStatus(e.target.value) : undefined
                        } >
                        {contractStatuses.map(contractStatus => {
                          return <Radio.Button value={contractStatus}>{props.getContractLabel(contractStatus)}</Radio.Button>
                        })}
                      </Radio.Group>
                    </Form.Item>
                    {props.currentContractStatus && props.currentContractStatus !== 'underContract' ?
                      <Form.Item
                      name="contractStatusUpdatedAt"
                      label="">
                        <DatePicker picker="month" format={'YYYY年MM月'} />
                      </Form.Item>
                    : ''}
                    <Button
                      size="small"
                      onClick={props.onPressCancelContractStatus}
                      style={{ marginLeft: 10 }}
                      disabled={props.isUpdatingContractStatus}>
                      キャンセル
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      style={{ marginLeft: 10 }}
                      htmlType="submit"
                      loading={props.isUpdatingContractStatus}>
                      変更する
                    </Button>
                  </Form>
              </>
            )}
            </Descriptions.Item>
            <Descriptions.Item label="登録日時" span={4}>
              {props.user?.createdAt?.toDate().toLocaleString('ja-JP')}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Radio.Group value={props.terms} onChange={props.onChangeTerms}>
            <Radio.Button value="week">過去7日間</Radio.Button>
            <Radio.Button value="month">過去28日間</Radio.Button>
          </Radio.Group>
          <StyledNewButton
            disabled={props.data.length === 0}
            onClick={props.onPressDownload}>
            ダウンロード
          </StyledNewButton>
          { props.data.length === 0 ?
              <Result
              status="warning"
              title="該当期間のスコアはありません。" />
            :
              <>
                <Line {...config} />
                <Line {...oilConfig} />
                <ScanReportsTable scanReports={props.scanReportsList} />
              </>
          }
        </ContentBody>
      </Layout>
    </Root>
  )
}
const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`

const StyledNewButton = styled(Button)`
  margin-left: 12px;
`
