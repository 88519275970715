import React from 'react'
import { useConsoleUsers } from '@/hooks/useConsoleUsers'
import { Layout } from './Layout'
import { message, Modal } from 'antd'
import { useFullScreenLoading } from '@/context/FullScreenLoadingContext'
import { ConsoleUserRequest } from '@/lib/request'
import { useSelector } from 'react-redux'

export const useProps = (): FCProps<typeof Layout> => {
  const { consoleUsers, updateUserRole } = useConsoleUsers()
  const { setVisible } = useFullScreenLoading()
  const idToken = useSelector<RootReducer, string | null>(({ idToken }) => idToken)

  const getRoleLabel = (role: App.User['role']): string => {
    switch (role) {
      case 'admin':
        return '管理者'
      case 'viewer':
        return '閲覧者'
      default:
        return ''
    }
  }

  return {
    consoleUsers,
    getRoleLabel,
    onPressRole: async (user: App.User, role: App.User['role']) => {
      if (window.confirm(`本当に${getRoleLabel(role)}に変更しますか？`)) {
        setVisible(true)
        try {
          await updateUserRole({ user, role })
          message.success(`${getRoleLabel(role)}への変更に成功しました`)
        } catch (e) {
          message.error(`${getRoleLabel(role)}への変更に失敗しました。${e.message}`)
        } finally {
          setVisible(false)
        }
      }
    },
    onPressPasswordResetLink: async (user: App.User) => {
      if (!idToken || !user) return
      setVisible(true)
      try {
        const response = await ConsoleUserRequest.postPasswordResetLink(idToken, {
          emailAddress: user.emailAddress,
        })
        Modal.info({
          title: 'パスワード設定リンク',
          content: <div>{response.passwordResetLink}</div>,
          onOk() {},
        })
      } catch (e) {
        message.error('パスワード設定リンクの取得に失敗しました')
      } finally {
        setVisible(false)
      }
    },
    onPressDelete: async (user: App.User) => {
      if (window.confirm(`${user.emailAddress}を本当に削除しますか？`)) {
        setVisible(true)
        try {
          await updateUserRole({ user, role: undefined })
          message.success('削除に成功しました')
        } catch (e) {
          message.error('削除に失敗しました')
        } finally {
          setVisible(false)
        }
      }
    },
  }
}
