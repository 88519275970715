import Layout from './Layout'
import { useState } from 'react'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form'
import { Api } from '@/api'
import { useConsoleUsers } from '@/hooks/useConsoleUsers'
import { ConsoleUserRequest } from '@/lib/request'
import { useSelector } from 'react-redux'

export const useProps = (form: FormInstance): FCProps<typeof Layout> => {
  const [isUploadingConsoleUser, setIsUploadingConsoleUser] = useState(false)
  const { updateUserRole } = useConsoleUsers()
  const idToken = useSelector<RootReducer, string | null>(({ idToken }) => idToken)
  const history = useHistory()

  return {
    form,
    isUploadingConsoleUser,
    onPressSubmit: async (values: { emailAddress: App.User['emailAddress']; role: App.User['role'] }) => {
      const emailAddress = values.emailAddress
      if (!idToken || !emailAddress) return
      setIsUploadingConsoleUser(true)
      let user = await Api.getUserByEmailAddress({ emailAddress })
      if (user && user.role) {
        message.error({
          content: '登録済の管理サイトユーザーです',
        })
        setIsUploadingConsoleUser(false)
        return
      }
      try {
        if (!user) {
          user = await ConsoleUserRequest.post(idToken, { emailAddress })
        }
        if (!user) return
        await updateUserRole({ user, role: values.role })
        message.success({
          content: '管理サイトユーザーの登録に成功しました',
        })
        history.push('/consoleUsers')
      } catch (e) {
        message.error({
          content: '管理サイトユーザーの登録に失敗しました',
        })
      } finally {
        setIsUploadingConsoleUser(false)
      }
    },
  }
}
