import Firebase from 'firebase/app'

export const FirestoreUtils = {
  deleteNullValue: (obj: object) =>
    Object.entries(obj).reduce(
      (p, [key, value]) => ({
        ...p,
        [key]: value ?? Firebase.firestore.FieldValue.delete(),
      }),
      {},
    ),
}
