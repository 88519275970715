import React, { useState, useEffect, useContext, createContext, FC } from 'react'
import { useSelector } from 'react-redux'
import { firestore } from '@/lib/firebase'

export const ConsoleUserContext = createContext<{
  admin: boolean
}>({} as any)

export const ConsoleUser: FC = ({ children }) => {
  const [admin, setAdmin] = useState<boolean>(false)
  const loginUser = useSelector<RootReducer, firebase.User | null>(({ loginUser }) => loginUser)

  useEffect(() => {
    const fetchIsAdmin = async () => {
      if (!loginUser) return
      const doc = await firestore
        .collection('users')
        .doc(loginUser.uid)
        .get()
      if (!doc.exists) return
      const data = doc.data() as App.User
      setAdmin(data.role === 'admin')
    }
    fetchIsAdmin()
  }, [loginUser])

  return <ConsoleUserContext.Provider value={{ admin }}>{children}</ConsoleUserContext.Provider>
}

export const useConsoleUser = () => {
  return useContext(ConsoleUserContext)
}
