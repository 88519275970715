import { usePreUsers } from '@/hooks/usePreUsers'
import { Layout } from './Layout'
import { useFullScreenLoading } from '@/context/FullScreenLoadingContext'
import { message } from 'antd'
import { Api } from '@/api'
import download from '@/lib/download'

export const useProps = (): FCProps<typeof Layout> => {
  const preUsers = usePreUsers()
  const { setVisible } = useFullScreenLoading()

  const downloadCsv = (selectedRowKeys: (string | number)[]) => {
    if (!selectedRowKeys) return

    const title = ['顧客番号', 'PINコード', 'アプリ登録状況', '有効/無効', '顧客番号登録日時'].join(',')
    const contents = preUsers
      .filter(p => p.id && selectedRowKeys.includes(p.id))
      .map(preUser => {
        return [
          preUser.customerNumber,
          preUser.pinCode,
          preUser.registered ? '登録済' : '未登録',
          preUser.revoked ? '無効' : '有効',
          preUser.createdAt.toDate().toLocaleString('ja-JP'),
        ].join(',')
      })
      .join('\n')
    download('preUsers.csv', title + '\n' + contents)
  }

  return {
    preUsers,
    onPressDownload: downloadCsv,
    onPressDelete: async id => {
      if (window.confirm('本当に削除しますか？')) {
        setVisible(true)
        try {
          await Api.deletePreUser(id)
          message.success('削除に成功しました')
        } catch (e) {
          message.error('削除に失敗しました')
        } finally {
          setVisible(false)
        }
      }
    },
    onPressRevoke: async (id, to) => {
      if (window.confirm('本当に無効化しますか？')) {
        setVisible(true)
        try {
          await Api.revokePreUser(id, to)
          message.success('無効化に成功しました')
        } catch (e) {
          message.error('無効化に失敗しました')
        } finally {
          setVisible(false)
        }
      }
    },
  }
}
