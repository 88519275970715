import Layout from './Layout'
import { useCoupon } from '@/hooks/useCoupon'
import { useState, useEffect } from 'react'
import { message } from 'antd'
import { useFullScreenLoading } from '@/context/FullScreenLoadingContext'
import { Api } from '@/api'
import { firestore } from '@/lib/firebase'
import { useHistory } from 'react-router-dom'

const usersCollectionRef = firestore.collection('users')
const userUsedCouponsCollectionRef = firestore.collectionGroup('userUsedCoupons')

type Params = {
  couponId: App.Coupon['id']
}

export const useProps = (params: Params): FCProps<typeof Layout> => {
  const [deliveredCouponsCount, setDeliveredCouponsCount] = useState<number>(0)
  const [usedCouponsCount, setUsedCouponsCount] = useState<number>(0)
  const coupon = useCoupon({ couponId: params.couponId })
  const history = useHistory()
  const { setVisible } = useFullScreenLoading()

  useEffect(() => {
    if (!coupon || !coupon.finishedAt) return

    usersCollectionRef
      .where('createdAt', '<=', coupon.finishedAt)
      .get()
      .then(snapshot => setDeliveredCouponsCount(snapshot.docs.length))

    userUsedCouponsCollectionRef
      .where('couponId', '==', coupon.id)
      .get()
      .then(snapshot => setUsedCouponsCount(snapshot.docs.length))
  }, [coupon])

  return {
    coupon,
    deliveredCouponsCount,
    usedCouponsCount,
    onPressEdit: (id: App.Coupon['id']) => {
      history.push(`${history.location.pathname}/${id}/edit`)
    },
    onPressDelete: async (id: App.Coupon['id']) => {
      if (window.confirm('本当に削除しますか？')) {
        setVisible(true)
        try {
          await Api.deleteCoupon(id)
          message.success('削除に成功しました')
        } catch (e) {
          message.error('削除に失敗しました')
        } finally {
          setVisible(false)
        }
      }
    },
  }
}
