import { useState, useEffect } from 'react'
import { Api } from '@/api'

type Props = {
  couponId?: App.Coupon['id']
}

export const useCoupon = (props: Props) => {
  const [coupon, setCoupon] = useState<App.Coupon>()

  useEffect(() => {
    props.couponId &&
      Api.getCoupon({ id: props.couponId }).then(item => {
        setCoupon(item)
      })
  }, [props.couponId])

  return coupon
}
