import Layout from './Layout'
import { useState } from 'react'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form'
import { Api } from '@/api'
import parse from 'csv-parse'
import moment from 'moment'
import Firebase from 'firebase/app'
import { UserValidator } from '@/validators/UserValidator'

type ContractStatusForm = {
  contractStatus: App.User['contractStatus']
  contractStatusUpdatedAt: moment.Moment
}

export const useProps = (form: FormInstance): FCProps<typeof Layout> => {
  const [users, setUsers] = useState<App.User[]>([])
  const [fileUploadStatus, setFileUploadStatus] = useState<FCProps<typeof Layout>['fileUploadStatus']>()
  const [isUploadingUsers, setIsUploadingUsers] = useState(false)
  const [currentContractStatus, setCurrentContractStatus] = useState<App.User['contractStatus']>()
  const history = useHistory()

  return {
    form,
    users,
    onUpdateFileUploadStatus: setFileUploadStatus,
    onStartUpload: ({ file }) => {
      file.text().then(text => {
        let fetchedUsers: App.User[] = []
        parse(text, {}, (_, data) => {
          const records: App.User['customerNumber'][] = data
          records
            .filter(r => r[0].length > 0)
            .map(async record => {
              const customerNumber = record[0].trim() as string
              const user = await Api.getUserByCustomerNumber({ customerNumber })
              if (user && !fetchedUsers.includes(user)) {
                fetchedUsers = [...fetchedUsers, user]
                setUsers(fetchedUsers)
              }
            })
        })
        setFileUploadStatus(undefined)
      })
    },
    fileUploadStatus,
    isUploadingUsers,
    onPressSubmit: async (values: ContractStatusForm) => {
      console.log(JSON.stringify(values))
      if (users.length === 0) {
        message.error({ content: '変更する顧客番号がありません' })
        return
      }
      if (values.contractStatus !== 'canceled' || window.confirm('本当に解約済に変更しますか？')) {
        setIsUploadingUsers(true)
        const payload = {
          contractStatus: values.contractStatus,
          contractStatusUpdatedAt: values.contractStatusUpdatedAt && values.contractStatus !== 'underContract'
            ? Firebase.firestore.Timestamp.fromDate(
              values.contractStatusUpdatedAt
                .set('date', 21)
                .set('hour', 0)
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', 0)
                .toDate(),
            )
          : undefined
        }
        if (UserValidator.isValidContractStatus(payload.contractStatus, payload.contractStatusUpdatedAt)) {
          users.map(async user => {
            const result = await Api.putUser(user.id, payload)
            if (!result) {
              message.error({ content: `顧客番号 ${user.customerNumber} の契約状況の変更に失敗しました` })
              return
            }
          })
          message.success({ content: '契約状況の変更に成功しました' })
          history.push('/users')
        } else {
          message.error('契約状況の変更に失敗しました')
        }
        setIsUploadingUsers(false)
      }
    },
    currentContractStatus,
    onChangeContractStatus: t => setCurrentContractStatus(t),
  }
}
