import Layout from './Layout'
import { useState } from 'react'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form'
import { Api } from '@/api'
import parse from 'csv-parse'

export const useProps = (form: FormInstance): FCProps<typeof Layout> => {
  const [customerNumbers, setCustomerNumbers] = useState<App.PreUser['customerNumber'][]>([])
  const [fileUploadStatus, setFileUploadStatus] = useState<FCProps<typeof Layout>['fileUploadStatus']>()
  const [isUploadingPreUsers, setIsUploadingPreUsers] = useState(false)
  const history = useHistory()

  return {
    form,
    customerNumbers,
    onUpdateFileUploadStatus: setFileUploadStatus,
    onStartUpload: ({ file }) => {
      file.text().then(text => {
        let fetchedCustomerNumbers: App.PreUser['customerNumber'][] = []
        parse(text, {}, (_, data) => {
          const records: App.PreUser['customerNumber'][] = data
          records
            .filter(r => r[0].length > 0)
            .map(async record => {
              const customerNumber = record[0].trim() as string
              const preUser = await Api.getPreUserByCustomerNumber({ customerNumber })
              if (!preUser && !fetchedCustomerNumbers.includes(customerNumber)) {
                fetchedCustomerNumbers = [...fetchedCustomerNumbers, customerNumber]
                setCustomerNumbers(fetchedCustomerNumbers)
              }
            })
        })
        setFileUploadStatus(undefined)
      })
    },
    fileUploadStatus,
    isUploadingPreUsers,
    onPressSubmit: async () => {
      if (customerNumbers.length === 0) {
        message.error({ content: '登録する顧客番号がありません' })
        return
      }
      setIsUploadingPreUsers(true)
      customerNumbers.map(async c => {
        const target = { customerNumber: c }
        const preUser = await Api.getPreUserByCustomerNumber(target)
        if (preUser) {
          message.error({ content: `${c} は登録済の顧客番号です` })
        } else {
          const result = await Api.postPreUser(target)
          if (!result) {
            message.error({ content: `顧客番号 ${c} の登録に失敗しました` })
            return
          }
        }
      })
      message.success({ content: '顧客番号の登録に成功しました' })
      history.push('/preUsers')
      setIsUploadingPreUsers(false)
    },
  }
}
