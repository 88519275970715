import React from 'react'
import { Layout } from './Layout'
import { useProps } from './useProps'

type Props = {
  scanReports: App.ScanReport[]
}

export const ScanReportsTable = (props: Props) => {
  return <Layout {...useProps(props)} />
}
