import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Form, Upload, Button, Card, Input, DatePicker, Switch, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

type Props = {
  type: 'edit' | 'new'
  form: FormInstance
  imageUrl: App.Coupon['imageUrl'] | undefined
  detailImageUrl: App.Coupon['detailImageUrl'] | undefined
  imageUploadStatus?: UploadFile<any>
  detailImageUploadStatus?: UploadFile<any>
  onStartUploadImage: (o: RcCustomRequestOptions) => void
  onStartUploadDetailImage: (o: RcCustomRequestOptions) => void
  onUpdateImageUploadStatus: (staus?: UploadFile<any>) => void
  onUpdateDetailImageUploadStatus: (staus?: UploadFile<any>) => void
  onPressSubmit: (values: any) => void
  isUploadingCoupon: boolean
}

const generateTitle = (type: Props['type']) => {
  return type === 'edit' ? 'クーポン編集' : '新規クーポン登録'
}
export default (props: Props) => {
  return (
    <Root title={generateTitle(props.type)}>
      <Layout>
        <StyledPageHeader title={generateTitle(props.type)} />
        <ContentBody>
          <Form {...layout} form={props.form} onFinish={props.onPressSubmit}>
            <Form.Item label="クーポン画像（一覧）">
              <Form.Item valuePropName="fileList" noStyle>
                <Upload.Dragger
                  name="file"
                  multiple={true}
                  showUploadList={true}
                  accept=".jpg, .jpeg, .png"
                  customRequest={props.onStartUploadImage}
                  fileList={props.imageUploadStatus && [props.imageUploadStatus]}
                  onChange={info => {
                    const file = info.file
                    props.onUpdateImageUploadStatus(file)
                  }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">クリックするかここにファイルをドラッグしてください</p>
                </Upload.Dragger>
                {props.imageUrl ? (
                  <Card
                    hoverable
                    style={{ width: 240 }}
                    cover={<img alt="Coupon" src={props.imageUrl} />}></Card>
                ) : null}
              </Form.Item>
            </Form.Item>
            <Form.Item label="クーポン画像（詳細）">
              <Form.Item valuePropName="fileList" noStyle>
                <Upload.Dragger
                  name="file"
                  multiple={true}
                  showUploadList={true}
                  accept=".jpg, .jpeg, .png"
                  customRequest={props.onStartUploadDetailImage}
                  fileList={props.detailImageUploadStatus && [props.detailImageUploadStatus]}
                  onChange={info => {
                    const file = info.file
                    props.onUpdateDetailImageUploadStatus(file)
                  }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">クリックするかここにファイルをドラッグしてください</p>
                </Upload.Dragger>
                {props.detailImageUrl ? (
                  <Card
                    hoverable
                    style={{ width: 240 }}
                    cover={<img alt="Coupon" src={props.detailImageUrl} />}></Card>
                ) : null}
              </Form.Item>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  期間&nbsp;
                  <Tooltip title="開始日時のタイミングでPUSH通知も送信されます。">
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="range">
              <DatePicker.RangePicker
                showTime
                format={'YYYY/MM/DD HH時'}
                placeholder={['開始日時', '終了日時']}
              />
            </Form.Item>
            <Form.Item label="備考" name="terms">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="公開" name="published" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item label=" " colon={false}>
              <Button type="primary" htmlType="submit" loading={props.isUploadingCoupon}>
                {props.type === 'edit' ? '変更を保存' : '登録'}
              </Button>
            </Form.Item>
          </Form>
        </ContentBody>
      </Layout>
    </Root>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
