import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Button, Card } from 'antd'
import moment from 'moment'

type Props = {
  coupons: App.Coupon[]
  onPressShow: (id: App.Coupon['id']) => void
  onPressEdit: (id: App.Coupon['id']) => void
  onPressDelete: (id: App.Coupon['id']) => void
}

export const Layout = (props: Props) => {
  return (
    <ContentBody>
      <Link to="/coupons/new">
        <StyledNewButton type="primary">新規登録</StyledNewButton>
      </Link>
      <Table
        dataSource={props.coupons}
        columns={[
          {
            title: '画像',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (imageUrl: App.Coupon['imageUrl']) => {
              return (
                <Card hoverable style={{ width: 240 }} cover={<img alt="Coupon" src={imageUrl} />}></Card>
              )
            },
          },
          {
            title: '登録日時',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.createdAt.toDate()).unix() - moment(b.createdAt.toDate()).unix(),
            defaultSortOrder: 'descend',
            render: (createdAt: firebase.firestore.Timestamp) => (
              <div style={{ fontSize: 13, width: 130 }}>{createdAt.toDate().toLocaleString('ja-JP')}</div>
            ),
          },
          {
            title: '',
            key: 'action',
            render: ({ id }: App.Coupon) => {
              return (
                <div style={{ display: 'flex' }}>
                  <StyledButton onClick={() => props.onPressShow(id)}>詳細</StyledButton>
                  <StyledButton onClick={() => props.onPressEdit(id)}>編集</StyledButton>
                  <StyledButton danger onClick={() => props.onPressDelete(id)}>
                    削除
                  </StyledButton>
                </div>
              )
            },
          },
        ]}
      />
    </ContentBody>
  )
}

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`

const StyledButton = styled(Button)`
  margin: 0 3px;
  font-size: 12px;
`

const StyledNewButton = styled(Button)`
  margin: 12px 3px;
  font-size: 12px;
`
