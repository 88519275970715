import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader } from 'antd'

const pageTitle = 'フェイススキャン分析'

export default () => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />

      <ContentBody>
        <iframe
          title="scanReports"
          width="100%"
          height="1400px"
          src={process.env.REACT_APP_DATA_STUDIO_SCAN_REPORTS_URL}
          style={{ border: '0' }}
          allowFullScreen></iframe>
      </ContentBody>
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
