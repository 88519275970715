import { useState, useEffect, useCallback } from 'react'
import { firestore, auth } from '@/lib/firebase'
import { UserRequest } from '@/lib/request'

const collectionRef = firestore.collection('users')

export const useUsers = () => {
  const [users, setUsers] = useState<App.User[]>([])
  useEffect(() => {
    return collectionRef.orderBy('createdAt', 'desc').onSnapshot(async a => {
      const p = await Promise.all(
        a.docs.map<Promise<App.User>>(async b => {
          const data = b.data()
          return {
            ...data,
            id: b.id,
          } as App.User
        }),
      )
      setUsers(p)
    })
  }, [])

  const deleteUser = useCallback(async (params: { id: App.User['id'] }) => {
    return new Promise(r => {
      auth.onAuthStateChanged(async a => {
        const token = await a?.getIdToken()
        await UserRequest.delete(params, token ?? '')
        r()
      })
    })
  }, [])

  return { users, deleteUser }
}
