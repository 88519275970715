import { useCoupons } from '@/hooks/useCoupons'
import { Layout } from './Layout'
import { useFullScreenLoading } from '@/context/FullScreenLoadingContext'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { Api } from '@/api'

export const useProps = (): FCProps<typeof Layout> => {
  const coupons = useCoupons()
  const { setVisible } = useFullScreenLoading()
  const history = useHistory()

  return {
    coupons,
    onPressShow: async id => {
      history.push(`${history.location.pathname}/${id}`)
    },
    onPressEdit: id => {
      history.push(`${history.location.pathname}/${id}/edit`)
    },
    onPressDelete: async id => {
      if (window.confirm('本当に削除しますか？')) {
        setVisible(true)
        try {
          await Api.deleteCoupon(id)
          message.success('削除に成功しました')
        } catch (e) {
          message.error('削除に失敗しました')
        } finally {
          setVisible(false)
        }
      }
    },
  }
}
