import dayjs from 'dayjs'

export const UserValidator = {
  isValidContractStatus: (contractStatus: App.User['contractStatus'], contractStatusUpdatedAt: App.User['contractStatusUpdatedAt']): boolean => {
    switch (contractStatus) {
      case 'underContract':
        return !contractStatusUpdatedAt
      case 'suspendScheduled':
      case'cancelScheduled':
        return !!contractStatusUpdatedAt && dayjs(contractStatusUpdatedAt.toDate()).isAfter(dayjs())
      case 'suspended':
      case 'canceled':
        return !!contractStatusUpdatedAt && dayjs(contractStatusUpdatedAt.toDate()).isBefore(dayjs())
      default:
        return false
    }
  }
}
