import { useNotifications } from '@/hooks/useNotifications'
import { Layout } from './Layout'

export const useProps = (): FCProps<typeof Layout> => {
  const { notifications } = useNotifications()

  return {
    notifications,
  }
}
