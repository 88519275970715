import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'

const collectionRef = firestore.collection('notifications')

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<App.Notification[]>([])
  useEffect(() => {
    return collectionRef.orderBy('publishedAt', 'desc').onSnapshot(async a => {
      const p = await Promise.all(
        a.docs.map<Promise<App.Notification>>(async b => {
          const data = b.data()
          return {
            ...data,
            id: b.id,
          } as App.Notification
        }),
      )
      setNotifications(p)
    })
  }, [])

  return { notifications }
}
