import React, { useState } from 'react'
import styled from 'styled-components'
import { Table, Button, Input, Space, Menu, Dropdown } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { SearchOutlined, DownOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useConsoleUser } from '@/context/ConsoleUserContext'

type Props = {
  consoleUsers: App.User[]
  getRoleLabel: (role: App.User['role']) => string
  onPressRole: (user: App.User, role: App.User['role']) => void
  onPressPasswordResetLink: (user: App.User) => void
  passwordResetLink?: string
  onPressDelete: (user: App.User) => void
}

type SearchParams = {
  text: string
  column: string
}

export const Layout = (props: Props) => {
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '', column: '' })
  const { admin } = useConsoleUser()

  const getColumnTitle = (dataIndex: string): string => {
    switch (dataIndex) {
      case 'emailAddress':
        return 'メールアドレス'
      default:
        return ''
    }
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<App.User> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node
          // }}
          placeholder={`${getColumnTitle(dataIndex)}を検索`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            リセット
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            検索
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      switch (dataIndex) {
        case 'emailAddress':
          return record.emailAddress
            ? record.emailAddress.toLowerCase().includes(value.toString().toLowerCase())
            : false
        default:
          return false
      }
    },
    render: text =>
      searchParams.column === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchParams.text]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: string) => {
    confirm()
    setSearchParams({
      text: selectedKeys[0],
      column: dataIndex,
    })
  }

  const handleReset = (clearFilters: any) => {
    clearFilters()
    setSearchParams({
      text: '',
      column: '',
    })
  }

  const roleMenu = (user: App.User) => {
    const roles = ['admin', 'viewer'] as App.User['role'][]
    return (
      <Menu>
        {roles.map(role => {
          return user.role !== role ? (
            <Menu.Item>
              <Link to="#" onClick={() => props.onPressRole(user, role)}>
                {`${props.getRoleLabel(role)}に変更`}
              </Link>
            </Menu.Item>
          ) : null
        })}
      </Menu>
    )
  }

  return (
    <ContentBody>
      {admin && (
        <>
          <Link to="/consoleUsers/new">
            <StyledNewButton type="primary">新規登録</StyledNewButton>
          </Link>
        </>
      )}

      <Table
        dataSource={props.consoleUsers}
        columns={[
          {
            title: 'メールアドレス',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            ...getColumnSearchProps('emailAddress'),
          },
          {
            title: '権限',
            dataIndex: 'role',
            key: 'role',
            filters: [
              { text: '管理者', value: 'admin' },
              { text: '閲覧者', value: 'viewer' },
            ],
            onFilter: (value, record) => record.contractStatus === value,
            render: (role: App.User['role']) => (
              <div style={{ fontSize: 13, width: 130 }}>{props.getRoleLabel(role)}</div>
            ),
          },
          {
            title: '登録日時',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.createdAt.toDate()).unix() - moment(b.createdAt.toDate()).unix(),
            defaultSortOrder: 'descend',
            render: (cteatedAt: firebase.firestore.Timestamp) => (
              <div style={{ fontSize: 13, width: 130 }}>{cteatedAt.toDate().toLocaleString('ja-JP')}</div>
            ),
          },
          {
            title: '',
            key: 'action',
            render: (user: App.User) => {
              return (
                <>
                  <div style={{ display: 'flex' }}>
                    {admin && (
                      <>
                        <Dropdown overlay={roleMenu(user)} placement="bottomLeft">
                          <Button danger>
                            権限の変更 <DownOutlined />
                          </Button>
                        </Dropdown>
                        <StyledButton danger onClick={() => props.onPressPasswordResetLink(user)}>
                          パスワード設定リンクの表示
                        </StyledButton>
                        <StyledButton danger onClick={() => props.onPressDelete(user)}>
                          削除
                        </StyledButton>
                      </>
                    )}
                  </div>
                </>
              )
            },
          },
        ]}
      />
    </ContentBody>
  )
}

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`

const StyledButton = styled(Button)`
  margin: 0 3px;
  font-size: 12px;
`

const StyledNewButton = styled(Button)`
  margin: 12px 3px;
  font-size: 12px;
`
