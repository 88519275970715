import { useState, useEffect, useCallback } from 'react'
import { firestore, auth } from '@/lib/firebase'
import { ArticleRequest } from '@/lib/request'

const collectionRef = firestore.collection('articles')

export const useArticles = (category: App.Article['category']) => {
  const [articles, setArticles] = useState<App.Article[]>([])
  useEffect(() => {
    return collectionRef.where('category', '==', category).onSnapshot(async a => {
      const p = await Promise.all(
        a.docs.map<Promise<App.Article>>(async b => {
          const data = b.data()
          return {
            ...data,
            id: b.id,
          } as App.Article
        }),
      )
      setArticles(p)
    })
  }, [])

  const deleteArticle = useCallback(async (params: { id: App.Article['id'] }) => {
    return new Promise(r => {
      auth.onAuthStateChanged(async a => {
        const token = await a?.getIdToken()
        await ArticleRequest.delete(token ?? '', params)
        r()
      })
    })
  }, [])

  return { articles, deleteArticle }
}
