import Layout from './Layout'
import { useState, useEffect } from 'react'
import { firebase } from '@/lib/firebase'
import { ArticleRequest } from '@/lib/request'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const generateId = () => new Date().toISOString()

type Params = {
  initial: {
    id?: App.Article['id']
    title?: App.Article['title']
    category: App.Article['category']
    featuredImageUrls?: string[]
    contents?: App.Article['contents']
    publishedAt?: App.Article['publishedAt']
  }
}

export const useProps = ({ initial }: Params): FCProps<typeof Layout> => {
  const [title, setTitle] = useState('')
  const [featuredImageUrls, setFeaturedImageUrls] = useState<string[]>([])
  const [isUploadingNewArticle, setIsUploadingNewArticle] = useState(false)
  const [featuredImageUploadStatus, setHeaderImageUploadStatus] = useState<
    FCProps<typeof Layout>['featuredImageUploadStatus']
  >()
  const [contents, setContents] = useState<App.ArticleContent[]>([
    {
      type: 'text',
      id: generateId(),
      order: 0,
      text: '',
    },
  ])
  const [publishedAt, setPublishedAt] = useState<moment.Moment | undefined>(moment())

  useEffect(() => {
    initial.title && setTitle(initial.title)
    initial.featuredImageUrls && setFeaturedImageUrls(initial.featuredImageUrls)
    initial.contents && setContents(initial.contents)
    initial.id && setPublishedAt(initial.publishedAt && moment(initial.publishedAt.toMillis()))
  }, [initial])

  const history = useHistory()
  return {
    type: initial.id ? 'edit' : 'new',
    category: initial.category,
    title,
    onChangeTitle: setTitle,
    featuredImageUrls,
    contents,
    onPressAddNewContent: type => {
      if (type === 'title') {
        setContents([...contents, { type: 'title', id: generateId(), order: 0, text: '' }])
      }
      if (type === 'text') {
        setContents([...contents, { type: 'text', id: generateId(), order: 0, text: '' }])
      }
      if (type === 'images') {
        setContents([...contents, { type: 'images', id: generateId(), order: 0, urls: [] }])
      }
      if (type === 'video') {
        setContents([...contents, { type: 'video', id: generateId(), order: 0, url: '' }])
      }
    },
    onPressDeleteContent: id => {
      setContents(contents.filter(c => c.id !== id))
    },
    featuredImageUploadStatus,
    onUpdateFeaturedImageUploadStatus: setHeaderImageUploadStatus,
    onUpdateContent: c => {
      setContents(contents.map(c2 => (c.id === c2.id ? c : c2)))
    },
    onStartUpload: ({ file, onError, onProgress, onSuccess, data, action }) => {
      const storageRef = firebase.storage().ref()
      const name = new Date().toISOString() + '.jpg'
      const uploadTask = storageRef.child(name).put(file, { contentType: 'image/jpeg' })
      uploadTask.on(
        'state_changed',
        function(snapshot) {
          onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }, file)
        },
        function(error) {
          onError(error)
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            setHeaderImageUploadStatus(undefined)
            setFeaturedImageUrls([downloadURL])
          })
        },
      )
    },
    isUploadingNewArticle,
    onPressSubmit: async () => {
      setIsUploadingNewArticle(true)
      const token = await new Promise<string>(resolve =>
        firebase.auth().onIdTokenChanged(user => user?.getIdToken().then(s => resolve(s)) ?? resolve(''))(),
      )
      const navigateToArticleList = () => {
        switch (initial.category) {
          case 'case':
            return history.push('/cases')
          case 'column':
            return history.push('/columns')
          case 'news':
            return history.push('/news')
        }
      }
      if (initial.id) {
        try {
          await ArticleRequest.update(token ?? '', {
            id: initial.id,
            title,
            category: initial.category,
            contents,
            featuredImageUrls,
            publishedAt:
              publishedAt &&
              publishedAt
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', 0),
          })
          message.success({
            content: '記事の編集が完了しました',
          })
          navigateToArticleList()
        } catch (e) {
          message.error({
            content: '記事の編集に失敗しました',
          })
        }
      } else {
        try {
          await ArticleRequest.postNewArticle(token ?? '', {
            title,
            category: initial.category,
            contents,
            featuredImageUrls,
            publishedAt:
              publishedAt &&
              publishedAt
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', 0),
          })
          message.success({
            content: '記事の投稿に成功しました。',
          })
          navigateToArticleList()
        } catch (e) {
          message.error({
            content: '記事の投稿に失敗しました',
          })
        }
      }
      setIsUploadingNewArticle(false)
    },
    onUpdatePublidhedAt: setPublishedAt,
    publishedAt,
  }
}
