import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

type Props = {
  scanReports: App.ScanReport[]
}

export const Layout = (props: Props) => {
  return (
    <Table
      dataSource={props.scanReports}
      columns={[
        {
          title: '計測日時',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: (a, b) => moment(a.createdAt.toDate()).unix() - moment(b.createdAt.toDate()).unix(),
          defaultSortOrder: 'descend',
          render: (cteatedAt: firebase.firestore.Timestamp) => (
            <div style={{ fontSize: 13, width: 130 }}>{cteatedAt.toDate().toLocaleString('ja-JP')}</div>
          ),
        },
        {
          title: 'トータルスコア',
          dataIndex: 'fullPointScore',
          key: 'fullPointScore',
          sorter: (a, b) => a.fullPointScore - b.fullPointScore,
        },
        {
          title: '水分',
          dataIndex: 'hydrationAverage',
          key: 'hydrationAverage',
          sorter: (a, b) => a.hydrationAverage - b.hydrationAverage,
        },
        {
          title: '酸素',
          dataIndex: 'skinHealthAverage',
          key: 'skinHealthAverage',
          sorter: (a, b) => a.skinHealthAverage - b.skinHealthAverage,
        },
        {
          title: '色素',
          dataIndex: 'melaninAverage',
          key: 'melaninAverage',
          sorter: (a, b) => a.melaninAverage - b.melaninAverage,
        },
        {
          title: '皮脂',
          dataIndex: 'oilAverage',
          key: 'oilAverage',
          sorter: (a, b) => a.oilAverage - b.oilAverage,
        },
        {
          title: '年齢',
          dataIndex: 'age',
          key: 'age',
        },
      ]}
    />
  )
}
