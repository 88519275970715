import { useState, useEffect, useCallback } from 'react'
import { firestore } from '@/lib/firebase'
import { Api } from '@/api'

const collectionRef = firestore.collection('users')

export const useConsoleUsers = () => {
  const [admins, setAdmins] = useState<App.User[]>([])
  const [viewers, setViewers] = useState<App.User[]>([])
  const [consoleUsers, setConsoleUsers] = useState<App.User[]>([])

  useEffect(() => {
    collectionRef.where('role', '==', 'admin').onSnapshot(async a => {
      const p = await Promise.all(
        a.docs.map<Promise<App.User>>(async b => {
          const data = b.data()
          return {
            ...data,
            id: b.id,
          } as App.User
        }),
      )
      setAdmins(p)
    })

    collectionRef.where('role', '==', 'viewer').onSnapshot(async a => {
      const p = await Promise.all(
        a.docs.map<Promise<App.User>>(async b => {
          const data = b.data()
          return {
            ...data,
            id: b.id,
          } as App.User
        }),
      )
      setViewers(p)
    })
  }, [])

  useEffect(() => {
    setConsoleUsers(admins.concat(viewers))
  }, [admins, viewers])

  const updateUserRole = useCallback(async (params: { user: App.User; role: App.User['role'] }) => {
    const currentUserRole = params.user.role

    if (currentUserRole === 'admin') {
      try {
        const snapshot = await firestore.collection('admins').get()
        const adminsCount = snapshot.docs.length
        if (adminsCount === 1) throw new Error('管理者は最低1人は必要です。')
      } catch (error) {
        throw error
      }
    }

    switch (params.role) {
      case 'admin':
        await Api.putUser(params.user.id, { role: params.role })
        await Api.postAdmin(params.user.id)
        break
      case 'viewer':
        await Api.putUser(params.user.id, { role: params.role })
        await Api.postViewer(params.user.id)
        break
      default:
        await Api.putUser(params.user.id, { role: undefined })
        break
    }

    switch (currentUserRole) {
      case 'admin':
        await Api.deleteAdmin(params.user.id)
        break
      case 'viewer':
        await Api.deleteViewer(params.user.id)
        break
      default:
        break
    }
  }, [])

  return { consoleUsers, updateUserRole }
}
