import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { ConsoleUsersTable } from '@/components/tables/ConsoleUsersTable'
import { Layout, PageHeader } from 'antd'

const pageTitle = '管理サイトユーザー管理'

export default () => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />
      <ConsoleUsersTable />
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`
