import Layout from './Layout'
import { useState, useEffect } from 'react'
import { message } from 'antd'
import { firebase } from '@/lib/firebase'
import Firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form'
import { Api } from '@/api'
import moment from 'moment'

type couponForm = {
  terms: string
  published: boolean
  range: [moment.Moment, moment.Moment]
}

export const useProps = (form: FormInstance, coupon?: App.Coupon): FCProps<typeof Layout> => {
  const [isUploadingCoupon, setIsUploadingCoupon] = useState(false)
  const [imageUrl, setImageUrl] = useState<App.Coupon['imageUrl'] | undefined>(coupon?.imageUrl)
  const [detailImageUrl, setDetailImageUrl] = useState<App.Coupon['detailImageUrl'] | undefined>(coupon?.imageUrl)
  const [imageUploadStatus, setImageUploadStatus] = useState<FCProps<typeof Layout>['imageUploadStatus']>()
  const [detailImageUploadStatus, setDetailImageUploadStatus] = useState<FCProps<typeof Layout>['imageUploadStatus']>()
  const history = useHistory()

  useEffect(() => {
    setImageUrl(coupon?.imageUrl)
    setDetailImageUrl(coupon?.detailImageUrl)
  }, [coupon])

  return {
    type: coupon?.id ? 'edit' : 'new',
    form,
    imageUrl,
    detailImageUrl,
    isUploadingCoupon,
    onUpdateImageUploadStatus: setImageUploadStatus,
    onUpdateDetailImageUploadStatus: setDetailImageUploadStatus,
    onStartUploadImage: ({ file, onError, onProgress }) => {
      const storageRef = firebase.storage().ref()
      const name = new Date().toISOString() + '.jpg'
      const uploadTask = storageRef.child(name).put(file, { contentType: 'image/jpeg' })
      uploadTask.on(
        'state_changed',
        function(snapshot) {
          onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }, file)
        },
        function(error) {
          onError(error)
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            setImageUploadStatus(undefined)
            setImageUrl(downloadURL)
          })
        },
      )
    },
    onStartUploadDetailImage: ({ file, onError, onProgress }) => {
      const storageRef = firebase.storage().ref()
      const name = new Date().toISOString() + '.jpg'
      const uploadTask = storageRef.child(name).put(file, { contentType: 'image/jpeg' })
      uploadTask.on(
        'state_changed',
        function(snapshot) {
          onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }, file)
        },
        function(error) {
          onError(error)
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            setDetailImageUploadStatus(undefined)
            setDetailImageUrl(downloadURL)
          })
        },
      )
    },
    imageUploadStatus,
    detailImageUploadStatus,
    onPressSubmit: async (values: couponForm) => {
      setIsUploadingCoupon(true)
      if (coupon?.id && imageUrl) {
        const params = {
          imageUrl,
          detailImageUrl,
          terms: values.terms ?? '',
          published: values.published,
          publishedAt: values.range[0]
            ? Firebase.firestore.Timestamp.fromDate(
                values.range[0]
                  .set('minutes', 0)
                  .set('seconds', 0)
                  .set('milliseconds', 0)
                  .toDate(),
              )
            : undefined,
          finishedAt: values.range[1]
            ? Firebase.firestore.Timestamp.fromDate(
                values.range[1]
                  .set('minutes', 0)
                  .set('seconds', 0)
                  .set('milliseconds', 0)
                  .toDate(),
              )
            : undefined,
        }
        const result = await Api.putCoupon(coupon.id, params)
        if (result) {
          message.success({
            content: 'クーポンの登録に成功しました。',
          })
          history.push('/coupons')
        } else {
          message.error({
            content: 'クーポンの登録に失敗しました',
          })
        }
      } else if (imageUrl) {
        const params = {
          imageUrl: imageUrl,
          terms: values.terms ?? '',
          published: values.published,
          publishedAt: values.range[0]
            ? Firebase.firestore.Timestamp.fromDate(
                values.range[0]
                  .set('minutes', 0)
                  .set('seconds', 0)
                  .set('milliseconds', 0)
                  .toDate(),
              )
            : undefined,
          finishedAt: values.range[1]
            ? Firebase.firestore.Timestamp.fromDate(
                values.range[1]
                  .set('minutes', 0)
                  .set('seconds', 0)
                  .set('milliseconds', 0)
                  .toDate(),
              )
            : undefined,
        }
        const result = await Api.postCoupon(params)
        if (result) {
          message.success({
            content: 'クーポンの登録に成功しました。',
          })
          history.push('/coupons')
        } else {
          message.error({
            content: 'クーポンの登録に失敗しました',
          })
        }
      }
      setIsUploadingCoupon(false)
    },
  }
}
