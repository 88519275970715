import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Button } from 'antd'
import moment from 'moment'

type Props = {
  articles: App.Article[]
  newArticleLink: string
  onPressDelete: (id: App.Article['id']) => void
  onPressEdit: (id: App.Article['id']) => void
}

export const Layout = ({ articles, onPressDelete, newArticleLink, onPressEdit }: Props) => (
  <ContentBody>
    <Link to={newArticleLink}>
      <StyledNewButton type="primary">新規登録</StyledNewButton>
    </Link>
    <Table
      dataSource={articles}
      columns={[
        {
          title: 'タイトル',
          dataIndex: 'title',
          key: 'title',
          render: (title: App.Article['title']) => {
            return <div style={{ fontSize: 14 }}>{title}</div>
          },
        },
        {
          title: '公開（予定）日時',
          dataIndex: 'publishedAt',
          key: 'publishedAt',
          sorter: (a, b) => {
            const d1 = a.publishedAt ? moment(a.publishedAt.toDate()).unix() : 0
            const d2 = b.publishedAt ? moment(b.publishedAt.toDate()).unix() : 0
            return d1 - d2
          },
          render: (publishedAt: App.Article['publishedAt']) => {
            return (
              <div style={{ fontSize: 13, width: 130 }}>
                {publishedAt ? publishedAt.toDate().toLocaleString('ja-JP') : '未定'}
              </div>
            )
          },
        },
        {
          title: '登録日時',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: (a, b) => moment(a.createdAt.toDate()).unix() - moment(b.createdAt.toDate()).unix(),
          defaultSortOrder: 'descend',
          render: (cteatedAt: firebase.firestore.Timestamp) => (
            <div style={{ fontSize: 13, width: 130 }}>{cteatedAt.toDate().toLocaleString('ja-JP')}</div>
          ),
        },
        {
          title: '',
          key: 'action',
          render: ({ id }: App.Article) => {
            return (
              <div style={{ display: 'flex' }}>
                <StyledButton onClick={() => onPressEdit(id)}>編集</StyledButton>
                <StyledButton danger onClick={() => onPressDelete(id)}>
                  削除
                </StyledButton>
              </div>
            )
          },
        },
      ]}
    />
  </ContentBody>
)

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`

const StyledButton = styled(Button)`
  margin: 0 3px;
  font-size: 12px;
`

const StyledNewButton = styled(Button)`
  margin: 12px 3px;
  font-size: 12px;
`
