import iconv from 'iconv-lite'

export default (filename: string, content: string) => {
  const convetedContent = iconv.encode(content, 'Shift_JIS')
  const blob = new Blob([convetedContent], { type: 'text/csv;charset=sjis;' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
}
