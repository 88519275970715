import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Form, Upload, Button, Table, Radio, DatePicker } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { InboxOutlined } from '@ant-design/icons'
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface'
import dayjs from 'dayjs'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}

type Props = {
  form: FormInstance
  users: App.User[]
  fileUploadStatus?: UploadFile<any>
  onStartUpload: (o: RcCustomRequestOptions) => void
  onUpdateFileUploadStatus: (staus?: UploadFile<any>) => void
  onPressSubmit: (values: any) => void
  isUploadingUsers: boolean
  currentContractStatus?: App.User['contractStatus']
  onChangeContractStatus: (contractStatus?: App.User['contractStatus']) => void
}

const contractStatuses = [
  'underContract',
  'canceled',
  'suspended',
  'cancelScheduled',
  'suspendScheduled',
] as App.User['contractStatus'][]

const getContractLabel = (contractStatus: App.User['contractStatus']): string => {
  switch (contractStatus) {
    case 'underContract':
      return '契約中'
    case 'canceled':
      return '解約済'
    case 'suspended':
      return '休止中'
    case 'cancelScheduled':
      return '解約予定'
    case 'suspendScheduled':
      return '休止予定'
    default:
      return ''
  }
}
const pageTitle = '契約状況一括変更'

export default (props: Props) => {

  return (
    <Root title={pageTitle}>
      <Layout>
        <StyledPageHeader title={pageTitle} />
        <ContentBody>
          <Form {...layout} form={props.form} onFinish={props.onPressSubmit}>
            <Form.Item label="CSVファイル">
              <Form.Item
                name="dagger"
                valuePropName="file"
                noStyle
                rules={[{ required: true, message: 'CSVファイルを添付してください。' }]}>
                <Upload.Dragger
                  name="file"
                  multiple={false}
                  showUploadList={true}
                  accept=".csv"
                  customRequest={props.onStartUpload}
                  fileList={props.fileUploadStatus && [props.fileUploadStatus]}
                  onChange={info => {
                    const file = info.file
                    props.onUpdateFileUploadStatus(file)
                  }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">クリックするかここにファイルをドラッグしてください</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="contractStatus"
              label="契約状況">
              <Radio.Group
                onChange={e =>
                  e.target?.value ? props.onChangeContractStatus(e.target.value) : undefined
                } >
                {contractStatuses.map(contractStatus => {
                  return <Radio.Button value={contractStatus}>{getContractLabel(contractStatus)}</Radio.Button>
                })}
              </Radio.Group>
            </Form.Item>
            {props.currentContractStatus && props.currentContractStatus !== 'underContract' ?
              <Form.Item
                name="contractStatusUpdatedAt"
                label="契約更新月">
                <DatePicker picker="month" format={'YYYY年MM月'} />
              </Form.Item>
            : ''}
            <Form.Item label=" " colon={false}>
              <Button type="primary" htmlType="submit" loading={props.isUploadingUsers}>
                登録
              </Button>
            </Form.Item>
          </Form>
          <Table
            dataSource={props.users}
            columns={[
              {
                title: '顧客番号',
                dataIndex: 'customerNumber',
                key: 'customerNumber',
              },
              {
                title: '契約状況',
                dataIndex: 'contractStatus',
                key: 'contractStatus',
                render: (contractStatus: App.User['contractStatus']) => (
                  <div style={{ fontSize: 13, width: 130 }}>{getContractLabel(contractStatus)}</div>
                ),
              },
              {
                title: '契約更新日時',
                dataIndex: 'contractStatusUpdatedAt',
                key: 'contractStatusUpdatedAt',
                render: (contractStatusUpdatedAt: firebase.firestore.Timestamp) => (
                  <div style={{ fontSize: 13, width: 130 }}>{contractStatusUpdatedAt ? dayjs(contractStatusUpdatedAt.toDate()).format('YYYY/MM/DD') : ''}</div>
                ),
              },
            ]}
          />
        </ContentBody>
      </Layout>
    </Root>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
