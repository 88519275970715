import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { configureStore } from '@/store'
import { Router } from '@/routes'
import '@/lib/firebase'
import { FullScreenLoading } from './context/FullScreenLoadingContext'
import { ConfigProvider } from 'antd'
import jaJP from 'antd/es/locale/ja_JP'
import { ConsoleUser } from './context/ConsoleUserContext'

const { store, persistor } = configureStore()

export default () => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={jaJP}>
        <PersistGate loading={null} persistor={persistor}>
          <FullScreenLoading>
            <ConsoleUser>
              <Router />
            </ConsoleUser>
          </FullScreenLoading>
        </PersistGate>
      </ConfigProvider>
    </Provider>
  )
}
