import { Moment } from 'moment'

const baseUrl = `https://asia-northeast1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`
// const baseUrl = 'http://localhost:5001/appabrik-skincatch-dev/asia-northeast1'

const postRequest = async <T extends {}>(endPoint: string, idToken: string, payload: any) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'POST',
    headers: makeHeaders(idToken),
    body: JSON.stringify(payload),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json() as Promise<T>
}

const putRequest = async (endPoint: string, idToken: string, payload: any) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'PUT',
    headers: makeHeaders(idToken),
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}

const deleteRequest = async (endPoint: string, idToken: string) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'DELETE',
    headers: makeHeaders(idToken),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}

const getRequest = async <T extends {}>(endPoint: string, idToken: string): Promise<T> => {
  const response = await fetch(
    makeUrl(endPoint),
    {
      method: 'GET',
      headers: makeHeaders(idToken)
    }
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json() as Promise<T>
}

const makeUrl = (endpoint: string) => {
  return `${baseUrl}/${endpoint}`
}

const makeHeaders = (idToken: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${idToken}`,
})

export const ArticleRequest = {
  postNewArticle: async (
    idToken: string,
    params: {
      featuredImageUrls: string[]
      title: string
      category: App.Category
      contents: App.ArticleContent[]
      publishedAt?: Moment
    },
  ) => {
    return postRequest<{
      article: Pick<App.Article, 'sourceUrl' | 'featuredImageUrls' | 'title' | 'contents' | 'category'>
    }>('api/articles', idToken, {
      article: {
        category: params.category,
        contents: params.contents,
        sourceUrl: '',
        title: params.title,
        featuredImageUrls: params.featuredImageUrls,
        publishedAtMillis: params.publishedAt?.toDate().getTime(),
      },
    })
  },
  delete: async (idToken: string, params: { id: App.Article['id'] }) => {
    return deleteRequest(`api/articles/${params.id}`, idToken)
  },
  update: async (
    idToken: string,
    params: {
      id: string
      featuredImageUrls: string[]
      title: string
      category: App.Category
      contents: App.ArticleContent[]
      publishedAt?: Moment
    },
  ) => {
    return putRequest(`api/articles/${params.id}`, idToken, {
      article: {
        id: params.id,
        category: params.category,
        contents: params.contents,
        sourceUrl: '',
        title: params.title,
        featuredImageUrls: params.featuredImageUrls,
        publishedAtMillis: params.publishedAt?.toDate().getTime(),
      },
    })
  },
}

export const UserRequest = {
  post: async (
    idToken: string,
    payload: {
      emailAddress: App.User['emailAddress']
      publishedAt: Date
    },
  ) => {
    return postRequest<App.User>('api/users', idToken, payload)
  },
  put: async (
    idToken: string,
    payload: {
      id: App.User['id']
      emailAddress: App.User['emailAddress']
      publishedAt: Date
    },
  ) => {
    return putRequest(`api/users/${payload.id}`, idToken, payload)
  },
  delete: async (payload: { id: App.User['id'] }, idToken: string) => {
    return deleteRequest(`api/users/${payload.id}`, idToken)
  },

  putEmail: async (
    idToken: string,
    payload: {
      id: App.User['id']
      emailAddress: App.User['emailAddress']
    },
  ) => {
    return putRequest(`api/users/${payload.id}/updateEmail`, idToken, payload)
  },

  getEmailVerified: async (
    idToken: string,
    payload: {
      id: App.User['id']
    },
  ) => {
    return getRequest<{ emailVerified: boolean }>(`api/users/${payload.id}/emailVerified`, idToken)
  },
}

export const ConsoleUserRequest = {
  post: async (
    idToken: string,
    payload: {
      emailAddress: App.User['emailAddress']
    },
  ) => {
    return postRequest<App.User>('api/consoleUsers', idToken, payload)
  },

  postPasswordResetLink: async (
    idToken: string,
    payload: {
      emailAddress: App.User['emailAddress']
    },
  ) => {
    return postRequest<{ passwordResetLink: string }>('api/consoleUsers/passwordResetLink', idToken, payload)
  },
}
