import React from 'react'
import Layout from './Layout'
import { useProps } from './useProps'
import { Form } from 'antd'

export const PreUsersBulkRegistration = () => {
  const [form] = Form.useForm()

  return <Layout {...useProps(form)} />
}
