import Layout from './Layout'
import { useUser } from '@/hooks/useUser'
import { useState, useEffect } from 'react'
import { UserRequest } from '@/lib/request'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { Api } from '@/api'
import dayjs, { Dayjs } from 'dayjs'
import Firebase from 'firebase/app'
import { firestore } from '@/lib/firebase'
import { useConsoleUser } from '@/context/ConsoleUserContext'
import moment from 'moment'
import { UserValidator } from '@/validators/UserValidator'
import download from '@/lib/download'

const collectionRef = firestore.collectionGroup('userScanReports')

type Params = {
  userId: App.User['id']
}

type Terms = 'week' | 'month'

type DataType = {
  date: string
  type: string
  value: number
}

type ContractStatusForm = {
  contractStatus: App.User['contractStatus']
  contractStatusUpdatedAt: moment.Moment
}

export const useProps = (params: Params): FCProps<typeof Layout> => {
  const [emailAddress, setEmailAddress] = useState<App.User['emailAddress']>('')
  const [emailVerified, setEmailVerified] = useState<boolean>(true)
  const [visibleEmailForm, setVisibleEmailForm] = useState<boolean>(false)
  const [isUpdatingEmail, setIsUpdatingEmail] = useState<boolean>(false)
  const [visibleContractStatusForm, setVisibleContractStatusForm] = useState<boolean>(false)
  const [currentContractStatus, setCurrentContractStatus] = useState<App.User['contractStatus']>()
  const [isUpdatingContractStatus, setIsUpdatingContractStatus] = useState<boolean>(false)
  const [startedOn, setStartedOn] = useState<Dayjs>(dayjs().subtract(6, 'day'))
  const [terms, setTerms] = useState<Terms>('week')
  const [scanReportsList, setScanReportsList] = useState<App.ScanReport[]>([])
  const [scanReportsChart, setScanReportsChart] = useState<App.ScanReport[]>([])
  const [data, setData] = useState<DataType[]>([])
  const [oilData, setOilData] = useState<DataType[]>([])
  const { admin } = useConsoleUser()

  const user = useUser({ userId: params.userId })
  const idToken = useSelector<RootReducer, string | null>(({ idToken }) => idToken)

  const getContractLabel = (contractStatus: App.User['contractStatus']): string => {
    switch (contractStatus) {
      case 'underContract':
        return '契約中'
      case 'canceled':
        return '解約済'
      case 'suspended':
        return '休止中'
      case 'cancelScheduled':
        return '解約予定'
      case 'suspendScheduled':
        return '休止予定'
      default:
        return ''
    }
  }

  const downloadCsv = () => {
    if (!user) return

    const title = [
      'ユーザーID',
      '顧客番号',
      '計測日時',
      'トータルスコア',
      '水分',
      '酸素',
      '色素',
      '皮脂',
      '年齢',
    ].join(',')
    const contents = scanReportsList
      .map(scanReport => {
        return [
          user.id,
          user.customerNumber,
          scanReport.createdAt.toDate().toLocaleString('ja-JP'),
          scanReport.fullPointScore,
          scanReport.hydrationAverage,
          scanReport.skinHealthAverage,
          scanReport.melaninAverage,
          scanReport.oilAverage,
          scanReport.age,
        ].join(',')
      })
      .join('\n')
    download(`scanReport_${user.id}.csv`, title + '\n' + contents)
  }


  useEffect(() => {
    if (!user) return
    setEmailAddress(user.emailAddress)
    setCurrentContractStatus(user.contractStatus)
  }, [user])

  useEffect(() => {
    if (!user || !idToken || !admin) return
    const fetchEmailVerified = async () => {
      const response = await UserRequest.getEmailVerified(idToken, { id: params.userId })
      if (response) setEmailVerified(response.emailVerified)
    }
    fetchEmailVerified()
  }, [user, idToken, admin])

  useEffect(() => {
    if (terms === 'week') {
      setStartedOn(dayjs().subtract(6, 'day'))
    } else if (terms === 'month') {
      setStartedOn(dayjs().subtract(27, 'day'))
    }
  }, [terms])

  useEffect(() => {
    if (!params.userId) return

    const startedAt = Firebase.firestore.Timestamp.fromDate(startedOn.startOf('date').toDate())

    collectionRef
      .where('userId', '==', params.userId)
      .where('createdAt', '>=', startedAt)
      .orderBy('createdAt', 'desc')
      .onSnapshot(async a => {
        setScanReportsList(
          a.docs.map<App.ScanReport>(b => {
            const data = b.data()
            return {
              ...data,
              id: b.id,
            } as App.ScanReport
          }),
        )
      })
    collectionRef
      .where('userId', '==', params.userId)
      .where('isLatest', '==', true)
      .where('createdAt', '>=', startedAt)
      .orderBy('createdAt', 'asc')
      .onSnapshot(async a => {
        setScanReportsChart(
          a.docs.map<App.ScanReport>(b => {
            const data = b.data()
            return {
              ...data,
              id: b.id,
            } as App.ScanReport
          }),
        )
      })
  }, [startedOn])

  useEffect(() => {
    let reports: DataType[] = []
    let oilReports: DataType[] = []
    scanReportsChart.forEach(scanReport => {
      const date = dayjs(scanReport.createdAt.toDate()).format('YYYY/MM/DD')
      reports = [
        ...reports,
        {
          date,
          type: 'トータル',
          value: scanReport.fullPointScore,
        },
        {
          date,
          type: '水分',
          value: scanReport.hydrationAverage,
        },
        {
          date,
          type: '酸素',
          value: scanReport.skinHealthAverage,
        },
        {
          date,
          type: '色素',
          value: scanReport.melaninAverage,
        },
      ]
      oilReports = [
        ...oilReports,
        {
          date,
          type: '油分',
          value: scanReport.oilAverage,
        },
      ]
      setData(reports)
      setOilData(oilReports)
    })
  }, [scanReportsChart])

  return {
    user,
    emailVerified,
    getContractLabel,
    visibleEmailForm,
    onPressEditEmail: () => setVisibleEmailForm(true),
    onChangeEmailAddress: t => setEmailAddress(t),
    onPressCancelEmail: () => setVisibleEmailForm(false),
    onSubmitEmail: async () => {
      if (!idToken || !emailAddress) return
      setIsUpdatingEmail(true)
      const response = await UserRequest.putEmail(idToken, { id: params.userId, emailAddress })
      if (response && !response.message) {
        setVisibleEmailForm(false)
        message.success({
          content: 'メールアドレスの変更に成功しました。',
        })
      } else {
        message.error({
          content: 'メールアドレスの変更に失敗しました',
        })
      }
      setIsUpdatingEmail(false)
    },
    isUpdatingEmail,
    visibleContractStatusForm,
    currentContractStatus,
    onPressEditContractStatus: () => setVisibleContractStatusForm(true),
    onChangeContractStatus: t => setCurrentContractStatus(t),
    onPressCancelContractStatus: () => setVisibleContractStatusForm(false),
    onSubmitContractStatus: async (values: ContractStatusForm) => {
      if (values.contractStatus !== 'canceled' || window.confirm('本当に解約済に変更しますか？')) {
        setIsUpdatingContractStatus(true)
        try {
          const payload = {
            contractStatus: values.contractStatus,
            contractStatusUpdatedAt: values.contractStatusUpdatedAt && values.contractStatus !== 'underContract'
              ? Firebase.firestore.Timestamp.fromDate(
                values.contractStatusUpdatedAt
                  .set('date', 21)
                  .set('hour', 0)
                  .set('minutes', 0)
                  .set('seconds', 0)
                  .set('milliseconds', 0)
                  .toDate(),
              )
            : undefined
          }
          if (UserValidator.isValidContractStatus(payload.contractStatus, payload.contractStatusUpdatedAt)) {
            if (payload.contractStatus === 'canceled') {
              if (!idToken) return
              const response = await UserRequest.delete({ id: params.userId }, idToken)
              if (!response) {
                message.error('契約状況の変更に失敗しました')
                return
              }
            }
            await Api.putUser(params.userId, payload)
            setVisibleContractStatusForm(false)
            message.success('契約状況の変更に成功しました')
          } else {
            message.error('契約状況の変更に失敗しました')
          }
        } catch (e) {
          message.error('契約状況の変更に失敗しました')
        } finally {
          setIsUpdatingContractStatus(false)
        }
      }
    },
    isUpdatingContractStatus,
    scanReportsList,
    scanReportsChart,
    terms,
    onChangeTerms: t => {
      t.target.value && setTerms(t.target.value)
    },
    data,
    oilData,
    onPressDownload: downloadCsv,
  }
}
