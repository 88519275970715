import Layout from './Layout'
import { useState, useEffect } from 'react'
import moment from 'moment'
import { firestore } from '@/lib/firebase'
import Firebase from 'firebase/app'
import dayjs from 'dayjs'

type couponForm = {
  terms: string
  published: boolean
  range: [moment.Moment, moment.Moment]
}

const collectionRef = firestore.collection('users')

const getAgeCount = async (start: number, end: number) => {
  return await collectionRef
    .where(
      'birthday',
      '<',
      Firebase.firestore.Timestamp.fromDate(
        dayjs()
          .subtract(start, 'year')
          .toDate(),
      ),
    )
    .where(
      'birthday',
      '>=',
      Firebase.firestore.Timestamp.fromDate(
        dayjs()
          .subtract(end, 'year')
          .toDate(),
      ),
    )
    .get()
    .then(s => s.size)
}

export const useProps = (): FCProps<typeof Layout> => {
  const [genderData, setGenderData] = useState<{ type: string; value: number }[]>([])
  const [ageData, setAgeData] = useState<{ type: string; value: number }[]>([])
  useEffect(() => {
    const fetchGenderData = async () => {
      const usersCount = await collectionRef.get().then(snap => snap.size)
      const malesCount = await collectionRef
        .where('gender', '==', 'male')
        .get()
        .then(snap => snap.size)
      const femalesCount = await collectionRef
        .where('gender', '==', 'female')
        .get()
        .then(snap => snap.size)
      const othersCount = await collectionRef
        .where('gender', '==', 'other')
        .get()
        .then(snap => snap.size)
      setGenderData([
        { type: '男性', value: malesCount },
        { type: '女性', value: femalesCount },
        { type: 'その他/未回答', value: othersCount },
        { type: '無回答', value: usersCount - malesCount - femalesCount - othersCount },
      ])
    }
    const fetchAgeData = async () => {
      const usersCount = await collectionRef.get().then(snap => snap.size)
      const underTwentyCount = await collectionRef
        .where(
          'birthday',
          '>=',
          Firebase.firestore.Timestamp.fromDate(
            dayjs()
              .subtract(10, 'year')
              .toDate(),
          ),
        )
        .get()
        .then(s => s.size)
      const twentiesCount = await getAgeCount(20, 30)
      const thirteensCount = await getAgeCount(30, 40)
      const fourteensCount = await getAgeCount(40, 50)
      const fifteensCount = await getAgeCount(50, 60)
      const sixteensCount = await getAgeCount(60, 70)
      const overSeventyCount = await collectionRef
        .where(
          'birthday',
          '<',
          Firebase.firestore.Timestamp.fromDate(
            dayjs()
              .subtract(70, 'year')
              .toDate(),
          ),
        )
        .get()
        .then(s => s.size)
      setAgeData([
        { type: '20歳未満', value: underTwentyCount },
        { type: '20代', value: twentiesCount },
        { type: '30代', value: thirteensCount },
        { type: '40代', value: fourteensCount },
        { type: '50代', value: fifteensCount },
        { type: '60代', value: sixteensCount },
        { type: '70歳以上', value: overSeventyCount },
        {
          type: '無回答',
          value:
            usersCount -
            underTwentyCount -
            twentiesCount -
            thirteensCount -
            fourteensCount -
            fifteensCount -
            sixteensCount -
            overSeventyCount,
        },
      ])
    }
    fetchGenderData()
    fetchAgeData()
  }, [])

  return {
    genderData,
    ageData,
  }
}
