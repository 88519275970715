import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Button, Input, Space } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { useConsoleUser } from '@/context/ConsoleUserContext'

type Props = {
  preUsers: App.PreUser[]
  onPressDownload: (selectedRowKeys: (string | number)[]) => void
  onPressDelete: (id: App.PreUser['id']) => void
  onPressRevoke: (id: App.PreUser['id'], to: App.PreUser['revoked']) => void
}

type SearchParams = {
  text: string
  column: string
}

export const Layout = (props: Props) => {
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '', column: '' })
  const [selectedRowKeys, setSelectedRowKeys] = useState<(string | number)[]>([])
  const [currentPageData, setCurrentPageData] = useState<App.PreUser[]>([])
  const { admin } = useConsoleUser()

  const getColumnTitle = (dataIndex: string): string => {
    switch (dataIndex) {
      case 'customerNumber':
        return '顧客番号'
      case 'createdAt':
        return '登録日時'
      default:
        return ''
    }
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<App.PreUser> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node
          // }}
          placeholder={`${getColumnTitle(dataIndex)}を検索`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => handleSearchReset(clearFilters)} size="small" style={{ width: 90 }}>
            リセット
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            検索
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      switch (dataIndex) {
        case 'customerNumber':
          return record.customerNumber
            ? record.customerNumber.toLowerCase().includes(value.toString().toLowerCase())
            : false
        case 'createdAt':
          return record.createdAt
            ? record.createdAt
                .toDate()
                .toLocaleString('ja-JP')
                .includes(value.toString().toLowerCase())
            : false
        default:
          return false
      }
    },
    render: text =>
      searchParams.column === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchParams.text]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys: any, confirm: () => void, dataIndex: string) => {
    confirm()
    setSearchParams({
      text: selectedKeys[0],
      column: dataIndex,
    })
  }

  const handleSearchReset = (clearFilters: any) => {
    clearFilters()
    setSearchParams({
      text: '',
      column: '',
    })
  }

  const onSelectChange = (selectedRowKeys: (string | number)[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: App.PreUser) => ({
      disabled: currentPageData.length !== 0 && !currentPageData.includes(record),
      name: record.customerNumber,
    }),
  }

  return (
    <ContentBody>
      {admin && (
        <>
          <Link to="/preUsers/new">
            <StyledNewButton type="primary">新規登録</StyledNewButton>
          </Link>
          <Link to="/preUsers/bulkRegistration">
            <StyledNewButton type="primary">一括登録</StyledNewButton>
          </Link>
          <StyledNewButton
            disabled={selectedRowKeys.length === 0}
            onClick={() => props.onPressDownload(selectedRowKeys)}>
            ダウンロード
          </StyledNewButton>
        </>
      )}
      <Table
        rowKey="id"
        dataSource={props.preUsers}
        rowSelection={rowSelection}
        onChange={(_patination, _filters, _sorter, extra) => {
          setCurrentPageData(extra.currentDataSource)
        }}
        columns={[
          {
            title: '顧客番号',
            dataIndex: 'customerNumber',
            key: 'customerNumber',
            ...getColumnSearchProps('customerNumber'),
          },
          {
            title: 'PINコード',
            dataIndex: 'pinCode',
            key: 'pinCode',
          },
          {
            title: 'アプリ登録状況',
            dataIndex: 'registered',
            key: 'registered',
            filters: [
              { text: '登録済', value: true },
              { text: '未登録', value: false },
            ],
            onFilter: (value, record) => record.registered === value,
            render: (registered: App.PreUser['registered']) => (
              <div style={{ fontSize: 13, width: 130 }}>{registered ? '登録済' : '未登録'}</div>
            ),
          },
          {
            title: '有効/無効',
            dataIndex: 'revoked',
            key: 'revoked',
            filters: [
              { text: '無効', value: true },
              { text: '有効', value: false },
            ],
            onFilter: (value, record) => record.revoked === value,
            render: (revoked: App.PreUser['revoked']) => (
              <div style={{ fontSize: 13, width: 130 }}>{revoked ? '無効' : '有効'}</div>
            ),
          },
          {
            title: '顧客番号登録日時',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.createdAt.toDate()).unix() - moment(b.createdAt.toDate()).unix(),
            defaultSortOrder: 'descend',
            ...getColumnSearchProps('createdAt'),
            render: (cteatedAt: firebase.firestore.Timestamp) => (
              <div style={{ fontSize: 13, width: 130 }}>{cteatedAt.toDate().toLocaleString('ja-JP')}</div>
            ),
          },
          {
            title: '',
            key: 'action',
            render: (preUser: App.PreUser) => {
              return (
                <div style={{ display: 'flex' }}>
                  {admin && !preUser.registered && (
                    <>
                      {preUser.revoked ? (
                        <StyledButton danger onClick={() => props.onPressRevoke(preUser.id, false)}>
                          有効化
                        </StyledButton>
                      ) : (
                        <StyledButton danger onClick={() => props.onPressRevoke(preUser.id, true)}>
                          無効化
                        </StyledButton>
                      )}
                      <StyledButton danger onClick={() => props.onPressDelete(preUser.id)}>
                        削除
                      </StyledButton>
                    </>
                  )}
                </div>
              )
            },
          },
        ]}
      />
    </ContentBody>
  )
}

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`

const StyledButton = styled(Button)`
  margin: 0 3px;
  font-size: 12px;
`

const StyledNewButton = styled(Button)`
  margin: 12px 3px;
  font-size: 12px;
`
