import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { ArticlesTable } from '@/components/tables/ArticlesTable'
import { Layout, PageHeader } from 'antd'

const pageTitle = 'お知らせ'

export default () => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />
      <ArticlesTable category="news" />
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`
