import React from 'react'
import { createRouter } from './createRouter'
import { Dashboard } from '@/pages/analytics/dashboard'
import { UserReports } from '@/pages/analytics/userReports'
import { ScanReports } from '@/pages/analytics/scanReports'
import LoginPage from '@/pages/login'
import { MonthlyScanReports } from '@/pages/monthlyScanReports'
import { Notifications } from '@/pages/notifications'
import News from '@/pages/news'
import { EditArticle } from '@/pages/edit_article'
import { Coupons } from '@/pages/coupons/list'
import { Coupon } from '@/pages/coupons/detail'
import { EditCoupon } from '@/pages/coupons/edit'
import { Users } from '@/pages/users/list'
import { User } from '@/pages/users/detail'
import { UsersBulkUpdate } from '@/pages/users/bulkUpdate'
import { EditPreUser } from '@/pages/preUsers/edit'
import { PreUsers } from '@/pages/preUsers/list'
import { ConsoleUsers } from '@/pages/consoleUsers/list'
import { EditConsoleUser } from '@/pages/consoleUsers/edit'
import { PreUsersBulkRegistration } from '@/pages/preUsers/bulkRegistration'

export const Routes: CustomRoute[] = [
  {
    needAuth: false,
    path: '/login',
    component: LoginPage,
  },
  {
    needAuth: true,
    path: '/notifications',
    component: Notifications,
  },
  {
    needAuth: true,
    path: '/news',
    component: News,
  },
  {
    needAuth: true,
    path: '/news/new',
    component: () => <EditArticle category="news" />,
  },
  {
    needAuth: true,
    path: '/news/:id',
    component: ({ params }) => {
      return <EditArticle category="news" articleId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/coupons',
    component: Coupons,
  },
  {
    needAuth: true,
    path: '/coupons/new',
    component: () => <EditCoupon />,
  },
  {
    needAuth: true,
    path: '/coupons/:id/edit',
    component: ({ params }) => {
      return <EditCoupon couponId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/coupons/:id',
    component: ({ params }) => {
      return <Coupon couponId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/users/bulkUpdate',
    component: UsersBulkUpdate,
  },
  {
    needAuth: true,
    path: '/users',
    component: Users,
  },
  {
    needAuth: true,
    path: '/users/:id',
    component: ({ params }) => {
      return <User userId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/preUsers/new',
    component: () => <EditPreUser />,
  },
  {
    needAuth: true,
    path: '/preUsers',
    component: PreUsers,
  },
  {
    needAuth: true,
    path: '/preUsers/bulkRegistration',
    component: PreUsersBulkRegistration,
  },
  {
    needAuth: true,
    path: '/consoleUsers',
    component: ConsoleUsers,
  },
  {
    needAuth: true,
    path: '/consoleUsers/new',
    component: () => <EditConsoleUser />,
  },
  {
    needAuth: true,
    path: '/',
    component: Dashboard,
  },
  {
    needAuth: true,
    path: '/analytics/userReports',
    component: UserReports,
  },
  {
    needAuth: true,
    path: '/analytics/scanReports',
    component: ScanReports,
  },
  {
    needAuth: true,
    path: '/analytics/monthlyScanReports',
    component: MonthlyScanReports,
  },
]

export const Router = createRouter(Routes)
