import Layout from './Layout'
import { useState } from 'react'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form'
import { Api } from '@/api'

export const useProps = (form: FormInstance): FCProps<typeof Layout> => {
  const [isUploadingPreUser, setIsUploadingPreUser] = useState(false)
  const history = useHistory()

  return {
    form,
    isUploadingPreUser,
    onPressSubmit: async (values: { customerNumber: string }) => {
      setIsUploadingPreUser(true)
      values.customerNumber = values.customerNumber.trim()
      const preUser = await Api.getPreUserByCustomerNumber(values)
      if (preUser) {
        message.error({
          content: '登録済の顧客番号です',
        })
      } else {
        const result = await Api.postPreUser(values)
        if (result) {
          message.success({
            content: '顧客番号の登録に成功しました。',
          })
          history.push('/preUsers')
        } else {
          message.error({
            content: '顧客番号の登録に失敗しました',
          })
        }
      }
      setIsUploadingPreUser(false)
    },
  }
}
