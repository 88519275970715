import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Form, Upload, Button, Table } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { InboxOutlined } from '@ant-design/icons'
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

type Props = {
  form: FormInstance
  customerNumbers: App.PreUser['customerNumber'][]
  fileUploadStatus?: UploadFile<any>
  onStartUpload: (o: RcCustomRequestOptions) => void
  onUpdateFileUploadStatus: (staus?: UploadFile<any>) => void
  onPressSubmit: (values: any) => void
  isUploadingPreUsers: boolean
}

const pageTitle = '新規顧客番号一括登録'

export default (props: Props) => {
  return (
    <Root title={pageTitle}>
      <Layout>
        <StyledPageHeader title={pageTitle} />
        <ContentBody>
          <Form {...layout} form={props.form} onFinish={props.onPressSubmit}>
            <Form.Item label="CSVファイル">
              <Form.Item
                name="dagger"
                valuePropName="file"
                noStyle
                rules={[{ required: true, message: 'CSVファイルを添付してください。' }]}>
                <Upload.Dragger
                  name="file"
                  multiple={false}
                  showUploadList={true}
                  accept=".csv"
                  customRequest={props.onStartUpload}
                  fileList={props.fileUploadStatus && [props.fileUploadStatus]}
                  onChange={info => {
                    const file = info.file
                    props.onUpdateFileUploadStatus(file)
                  }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">クリックするかここにファイルをドラッグしてください</p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item label=" " colon={false}>
                <Button type="primary" htmlType="submit" loading={props.isUploadingPreUsers}>
                  登録
                </Button>
              </Form.Item>
            </Form.Item>
          </Form>
          <Table
            dataSource={props.customerNumbers.map(c => {
              return {
                customerNumber: c,
              }
            })}
            columns={[
              {
                title: '顧客番号',
                dataIndex: 'customerNumber',
                key: 'customerNumber',
              },
            ]}
          />
        </ContentBody>
      </Layout>
    </Root>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
