import { firestore } from '@/lib/firebase'
import Firebase from 'firebase/app'
import { generatePinCode } from '@/lib/generatePinCode'
import { FirestoreUtils } from '@/utils/FirestoreUtils'
import { ObjectUtils } from '@/utils/ObjectUtils'

export const Api = {
  getArticle: (params: { id: App.Article['id'] }): Promise<App.Article> =>
    firestore
      .collection('articles')
      .doc(params.id)
      .get()
      .then(doc => ({ id: params.id, ...doc.data() })) as any,

  getNotification: (params: { id: App.Notification['id'] }): Promise<App.Notification> =>
    firestore
      .collection('notifications')
      .doc(params.id)
      .get()
      .then(doc => ({ id: params.id, ...doc.data() })) as any,

  getCoupon: (params: { id: App.Coupon['id'] }): Promise<App.Coupon> =>
    firestore
      .collection('coupons')
      .doc(params.id)
      .get()
      .then(doc => ({ id: params.id, ...doc.data() })) as any,

  getPreUserByCustomerNumber: async (params: {
    customerNumber: App.PreUser['customerNumber']
  }): Promise<App.PreUser | undefined> => {
    const customerNumber = params.customerNumber.trim()
    const snapshot = await firestore
      .collection('preUsers')
      .where('customerNumber', '==', customerNumber)
      .get()
    if (snapshot.empty) return undefined
    const doc = snapshot.docs[0]
    return { id: doc.id, ...doc.data() } as App.PreUser
  },

  getUserByEmailAddress: async (params: {
    emailAddress: App.User['emailAddress']
  }): Promise<App.User | undefined> => {
    const snapshot = await firestore
      .collection('users')
      .where('emailAddress', '==', params.emailAddress)
      .get()
    if (snapshot.empty) return undefined
    const doc = snapshot.docs[0]
    return { id: doc.id, ...doc.data() } as App.User
  },

  getUserByCustomerNumber: async (params: {
    customerNumber: App.User['customerNumber']
  }): Promise<App.User | undefined> => {
    const customerNumber = params.customerNumber.trim()
    const snapshot = await firestore
      .collection('users')
      .where('customerNumber', '==', customerNumber)
      .get()
    if (snapshot.empty) return undefined
    const doc = snapshot.docs[0]
    return { id: doc.id, ...doc.data() } as App.User
  },

  postCoupon: async (params: CouponParams) => {
    try {
      await firestore
        .collection('coupons')
        .add({ ...ObjectUtils.deleteUndefinedKey(params), createdAt: Firebase.firestore.Timestamp.now() })
      return true
    } catch (error) {
      throw error
    }
  },

  postPreUser: async (params: PreUserParams) => {
    try {
      await firestore.collection('preUsers').add({
        ...params,
        pinCode: generatePinCode(6),
        registered: false,
        revoked: false,
        createdAt: Firebase.firestore.Timestamp.now(),
      })
      return true
    } catch (error) {
      throw error
    }
  },

  putCoupon: async (id: App.Coupon['id'], params: CouponParams) => {
    try {
      await firestore
        .collection('coupons')
        .doc(id)
        .set(FirestoreUtils.deleteNullValue(params), { merge: true })
      return true
    } catch (error) {
      throw error
    }
  },

  deleteCoupon: async (id: App.Coupon['id']) => {
    try {
      await firestore
        .collection('coupons')
        .doc(id)
        .delete()
      return true
    } catch (error) {
      throw error
    }
  },

  deletePreUser: async (id: App.PreUser['id']) => {
    try {
      await firestore
        .collection('preUsers')
        .doc(id)
        .delete()
      return true
    } catch (error) {
      throw error
    }
  },

  revokePreUser: async (id: App.PreUser['id'], to: App.PreUser['revoked']) => {
    try {
      await firestore
        .collection('preUsers')
        .doc(id)
        .set({ revoked: to }, { merge: true })
      return true
    } catch (error) {
      throw error
    }
  },

  putUser: async (id: App.User['id'], params: UserParams) => {
    try {
      await firestore
        .collection('users')
        .doc(id)
        .set(FirestoreUtils.deleteNullValue(params), { merge: true })
      return true
    } catch (error) {
      throw error
    }
  },

  postAdmin: async (id: App.User['id']) => {
    try {
      await firestore
        .collection('admins')
        .doc(id)
        .set({})
      return true
    } catch (error) {
      throw error
    }
  },

  deleteAdmin: async (id: App.User['id']) => {
    try {
      await firestore
        .collection('admins')
        .doc(id)
        .delete()
      return true
    } catch (error) {
      throw error
    }
  },

  postViewer: async (id: App.User['id']) => {
    try {
      await firestore
        .collection('viewers')
        .doc(id)
        .set({})
      return true
    } catch (error) {
      throw error
    }
  },

  deleteViewer: async (id: App.User['id']) => {
    try {
      await firestore
        .collection('viewers')
        .doc(id)
        .delete()
      return true
    } catch (error) {
      throw error
    }
  },
}

type CouponParams = {
  imageUrl: string
  terms: string
  published: boolean
  startAt?: import('firebase').firestore.Timestamp
  endAt?: import('firebase').firestore.Timestamp
}

type PreUserParams = {
  customerNumber: App.PreUser['customerNumber']
}

type UserParams = {
  contractStatus?: App.User['contractStatus']
  contractStatusUpdatedAt?: App.User['contractStatusUpdatedAt']
  role?: App.User['role']
}
