import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Form, Input, Button } from 'antd'
import { FormInstance } from 'antd/lib/form'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

type Props = {
  form: FormInstance
  onPressSubmit: (values: any) => void
  isUploadingPreUser: boolean
}

const pageTitle = '新規顧客番号登録'

export default (props: Props) => {
  return (
    <Root title={pageTitle}>
      <Layout>
        <StyledPageHeader title={pageTitle} />
        <ContentBody>
          <Form {...layout} form={props.form} onFinish={props.onPressSubmit}>
            <Form.Item
              name="customerNumber"
              label="顧客番号"
              rules={[{ required: true, message: '顧客番号は必須です' }]}>
              <Input placeholder="顧客番号" />
            </Form.Item>
            <Form.Item label=" " colon={false}>
              <Button type="primary" htmlType="submit" loading={props.isUploadingPreUser}>
                登録
              </Button>
            </Form.Item>
          </Form>
        </ContentBody>
      </Layout>
    </Root>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
