import React, { useEffect } from 'react'
import Layout from './Layout'
import { useCoupon } from 'src/hooks/useCoupon'
import { useProps } from './useProps'
import { Form } from 'antd'
import moment from 'moment'

type Props = {
  couponId?: App.Coupon['id']
}

export const EditCoupon = (props: Props) => {
  const coupon = useCoupon({ couponId: props.couponId })
  const [form] = Form.useForm()

  useEffect(() => {
    coupon &&
      form.setFieldsValue({
        imageUrl: coupon.imageUrl,
        terms: coupon.terms,
        published: coupon.published,
        range: [
          coupon.publishedAt ? moment(coupon.publishedAt.toMillis()) : undefined,
          coupon.finishedAt ? moment(coupon.finishedAt.toMillis()) : undefined,
        ],
      })
  }, [coupon])
  return <Layout {...useProps(form, coupon)} />
}
