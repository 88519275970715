import { useRouteMatch } from 'react-router'
import { logout } from '@/lib/firebase'
import { Props, LayoutProps } from './props'

export default ({ title, children }: Props): LayoutProps => {
  document.title = `${title} | SkinCatch`
  const { path: currentPath } = useRouteMatch()
  const selectedKey = currentSelectedKey(currentPath)

  return {
    selectedKey,
    logout,
    children,
  }
}

const currentSelectedKey = (path: string): string => {
  if (path.match(/^\/notifications/) !== null) {
    return 'notifications'
  } else if (path.match(/^\/news/) !== null) {
    return 'news'
  } else if (path.match(/^\/columns/) !== null) {
    return 'columns'
  } else if (path.match(/^\/coupons/) !== null) {
    return 'coupons'
  } else if (path.match(/^\/users/) !== null) {
    return 'users'
  } else if (path.match(/^\/preUsers/) !== null) {
    return 'preUsers'
  } else if (path.match(/^\/consoleUsers/) !== null) {
    return 'consoleUsers'
  } else if (path.match(/^\/analytics\/userReports/) !== null) {
    return 'userReports'
  } else if (path.match(/^\/analytics\/scanReports/) !== null) {
    return 'scanReports'
  } else if (path.match(/^\/analytics\/monthlyScanReports/) !== null) {
    return 'monthlyScanReports'
  } else {
    return 'dashboard'
  }
}
