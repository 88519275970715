const DEFAULT_ALPHABET = '123456789'

const getRandomCharFromAlphabet = (alphabet: string): string => {
  return alphabet.charAt(Math.floor(Math.random() * alphabet.length))
}

export const generatePinCode = (idDesiredLength: number, alphabet = DEFAULT_ALPHABET): string => {
  return Array.from({ length: idDesiredLength })
    .map(() => {
      return getRandomCharFromAlphabet(alphabet)
    })
    .join('')
}
