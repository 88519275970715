import React from 'react'
import Layout from './Layout'
import { useProps } from './useProps'
import { Form } from 'antd'

type Props = {
  preUserId?: App.Coupon['id']
}

export const EditPreUser = (props: Props) => {
  const [form] = Form.useForm()

  return <Layout {...useProps(form)} />
}
