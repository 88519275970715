import { Layout } from './Layout'

type Props = {
  scanReports: App.ScanReport[]
}

export const useProps = (props: Props): FCProps<typeof Layout> => {
  const scanReports = props.scanReports

  return {
    scanReports,
  }
}
