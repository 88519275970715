import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'
import dayjs from 'dayjs'
import Firebase from 'firebase/app'

const collectionRef = firestore.collection('monthlyScanReports')

export const useMonthlyScanReports = () => {
  const [monthlyScanReports, setMonthlyScanReports] = useState<App.MonthlyScanReport[]>([])

  useEffect(() => {
    return collectionRef.where('startAt', '<=', Firebase.firestore.Timestamp.fromDate(
      dayjs().toDate())).onSnapshot(async a => {
      const p = await Promise.all(
        a.docs.map<Promise<App.MonthlyScanReport>>(async b => {
          const data = b.data()
          return {
            ...data,
            id: b.id,
          } as App.MonthlyScanReport
        }),
      )
      setMonthlyScanReports(p)
    })
  }, [])

  return { monthlyScanReports }
}
