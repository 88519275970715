import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'

const collectionRef = firestore.collection('preUsers')

export const usePreUsers = () => {
  const [preUsers, setPreUsers] = useState<App.PreUser[]>([])
  useEffect(() => {
    return collectionRef.orderBy('createdAt', 'desc').onSnapshot(async a => {
      const p = await Promise.all(
        a.docs.map<Promise<App.PreUser>>(async b => {
          const data = b.data()
          return {
            ...data,
            id: b.id,
          } as App.PreUser
        }),
      )
      setPreUsers(p)
    })
  }, [])

  return preUsers
}
