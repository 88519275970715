import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Row, Col } from 'antd'
import { Pie, Column } from '@ant-design/charts'

type Props = {
  genderData: { type: string; value: number }[]
  ageData: { type: string; value: number }[]
}

const pageTitle = 'ダッシュボード'

export default (props: Props) => {
  const genderConfig = {
    forceFit: true,
    title: {
      visible: true,
      text: '性別',
    },
    padding: 'auto',
    data: props.genderData,
    colorField: 'type',
    angleField: 'value',
    label: {
      visible: true,
      type: 'inner',
    },
    responsive: true,
    smooth: true,
  }

  const ageConfig = {
    forceFit: true,
    title: {
      visible: true,
      text: '年代',
    },
    padding: 'auto',
    data: props.ageData,
    xField: 'type',
    yField: 'value',
    meta: {
      type: {
        alias: '年代',
      },
      value: {
        alias: '人数',
      },
    },
    responsive: true,
    smooth: true,
  }

  return (
    <Root title={pageTitle}>
      <Layout>
        <StyledPageHeader title={pageTitle} />

        <ContentBody>
          <iframe
            title="Dashboard"
            width="100%"
            height="1400px"
            src={process.env.REACT_APP_DATA_STUDIO_DASHBOARD_URL}
            style={{ border: '0' }}
            allowFullScreen></iframe>
          <Row justify="start">
            <Col span={11}>
              <Pie {...genderConfig} />
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Column {...ageConfig} />
            </Col>
          </Row>
        </ContentBody>
      </Layout>
    </Root>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
