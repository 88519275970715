import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { LayoutProps } from './props'
import { Layout, Menu } from 'antd'
import { LineChartOutlined, NotificationOutlined, EditOutlined, UserOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

export default ({ selectedKey, logout, children }: LayoutProps) => (
  <RootLayout>
    <Sider collapsible>
      <Logo></Logo>
      <Menu
        theme="dark"
        defaultSelectedKeys={[selectedKey]}
        defaultOpenKeys={['analytics', 'posts', 'userManagement']}
        mode="inline">
        <SubMenu
          key="analytics"
          title={
            <span>
              <LineChartOutlined />
              <span>分析</span>
            </span>
          }>
          <Menu.Item key="dashboard">
            <Link to="/">
              <span>ダッシュボード</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="userReports">
            <Link to="/analytics/userReports">
              <span>ユーザー分析</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="scanReports">
            <Link to="/analytics/scanReports">
              <span>フェイススキャン分析</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="monthlyScanReports">
            <Link to="/analytics/monthlyScanReports">
              <span>月次集計結果</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="posts"
          title={
            <span>
              <EditOutlined />
              <span>投稿</span>
            </span>
          }>
          <Menu.Item key="news">
            <Link to="/news">
              <span>お知らせ</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="coupons">
            <Link to="/coupons">
              <span>クーポン</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="notifications">
          <Link to="/notifications">
            <NotificationOutlined />
            <span>PUSH通知履歴</span>
          </Link>
        </Menu.Item>
        <SubMenu
          key="userManagement"
          title={
            <span>
              <UserOutlined />
              <span>ユーザー管理</span>
            </span>
          }>
          <Menu.Item key="users">
            <Link to="/users">
              <span>アプリユーザー</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="preUsers">
            <Link to="/preUsers">
              <span>顧客番号/PINコード</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="consoleUsers">
            <Link to="/consoleUsers">
              <span>管理サイトユーザー</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
    <Layout>
      <StyledHeader>
        <Menu mode="horizontal">
          <Menu.Item>
            <LogoutLink onClick={logout}>ログアウト</LogoutLink>
          </Menu.Item>
        </Menu>
      </StyledHeader>
      <ChildContent>{children}</ChildContent>
      <StyledFooter>
        Created by{' '}
        <a href="https://www.appabrik.com/ja" target="_blank" rel="noopener noreferrer">
          Appabrik
        </a>
      </StyledFooter>
    </Layout>
  </RootLayout>
)

const RootLayout = styled(Layout)`
  min-height: 100vh;
`

const Logo = styled.div`
  height: 26px;
  background-image: url('./logo.png');
  background-size: cover;
  margin: 16px;
`

const StyledHeader = styled(Header)`
  padding: 0;
  background: #fff;
  text-align: right;
`

const LogoutLink = styled.div`
  cursor: pointer;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.7;
  }
`

const ChildContent = styled(Content)`
  margin: 0 16px;
`

const StyledFooter = styled(Footer)`
  text-align: center;
`
